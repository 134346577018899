"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateShiftBookingCancellationReasonFactory = void 0;
const locales_1 = require("../locales");
const translateShiftBookingCancellationReasonFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('shiftBookingCancellationReason', locale);
    return (shiftBookingCancellationReason) => {
        return t[shiftBookingCancellationReason];
    };
};
exports.translateShiftBookingCancellationReasonFactory = translateShiftBookingCancellationReasonFactory;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftHours = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftHours = {
    [enums_1.ShiftHours.DAY]: 'Day',
    [enums_1.ShiftHours.EVENING]: 'Evening',
    [enums_1.ShiftHours.NIGHT]: 'Night',
    [enums_1.ShiftHours.LONG]: 'Long',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftHours = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftHours = {
    [enums_1.ShiftHours.DAY]: 'Dag',
    [enums_1.ShiftHours.EVENING]: 'Kveld',
    [enums_1.ShiftHours.NIGHT]: 'Natt',
    [enums_1.ShiftHours.LONG]: 'Lang',
};

import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { TableRow } from './Row'
import { useTableProps } from './useTableProps'
import { mediaBreakpointGte } from '../imports'

const HeadElement = styled.thead<TableHeadProps>((props) => ({
  ...(props.responsive
    ? {
        display: 'none',
        [mediaBreakpointGte('md')]: {
          display: 'table-header-group',
        },
      }
    : {}),
}))

export type TableHeadProps = {
  children?: ReactNode
  /**
   * @deprecated
   */
  responsive?: boolean
}

export const TableHead = (props: TableHeadProps) => {
  const responsive = useTableProps().responsive ?? props.responsive

  return (
    <HeadElement responsive={responsive}>
      <TableRow head>{props.children}</TableRow>
    </HeadElement>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.availability_schedule = exports.jobs = exports.timesheets = exports.shifts = exports.availability = exports.unit_relation = exports.candidate_view = exports.common = exports.chat_view = void 0;
const enums_1 = require("@bemlo/enums");
const i18n_1 = require("@bemlo/i18n");
const utils_1 = require("@bemlo/utils");
const list_1 = require("../list");
const LOCALE = enums_1.Locale.NB;
const $country = (0, i18n_1.translateCountryFactory)(LOCALE);
const $licenseStatus = (0, i18n_1.translateUsingBothKeyAndValue)(enums_1.LicenseStatus, {
    [enums_1.LicenseStatus.NO_LICENSE]: 'Mangler autorisasjon',
    [enums_1.LicenseStatus.HAS_APPLIED]: 'Har søkt om autorisasjon',
    [enums_1.LicenseStatus.HAS_LICENSE]: 'Har autorisasjon',
});
const $list = (0, list_1.listFactory)('og');
const $market = (market) => $country((0, utils_1.countryForMarket)(market));
const $profession = (0, i18n_1.translateProfessionFactory)(LOCALE);
exports.chat_view = {
    conversation_other: () => 'Samtaler',
    internal: () => 'Intern',
    internal_conversation: () => 'Intern samtale',
    join: () => 'Bli med',
    new: () => 'Ny',
    new_conversation: () => 'Ny samtale',
    no_conversation_heading: (isBrowsable) => isBrowsable ? 'Ingen samtale valgt' : 'Ingen samtale',
    no_conversation_paragraph: (isBrowsable, isCreatable) => ({
        'false,false': 'Det er ingen samtale her ennå.',
        'false,true': 'Start en ny samtale for å komme i gang.',
        'true,false': 'Klikk på en samtale for å starte.',
        'true,true': 'Velg en eksisterende samtale eller start en ny.',
    })[`${isBrowsable},${isCreatable}`],
    no_subject: () => 'ingen emne',
    send: () => 'Send',
    start_conversation: () => 'Start samtale',
    write_a_message: () => 'Skriv en melding...',
};
exports.common = {
    accepted_scope: () => 'Akseptert omfang',
    booked_scope: () => 'Booket omfang',
    close: () => 'Lukk',
    minutes: () => 'Minutter',
    percent_sign: (value) => `${value} %`,
    period: () => 'Periode',
    presented_scope: () => 'Presentert omfang',
    remaining_scope: () => 'Gjenstående omfang',
    scope: () => 'Omfang',
    search: () => 'Søk',
    tendered_scope: () => 'Tilbudt omfang',
    this_month: () => 'Denne måneden',
    this_week: () => 'Denne uken',
    time_of_day: () => 'Tid på dagen',
    today: () => 'I dag',
    total: () => 'Totalt',
};
exports.candidate_view = (() => {
    const base = {
        authorized: () => 'Autorisert',
        cancel: () => 'Avbryt',
        drivers_license: () => 'Førerkort',
        last_updated: () => 'Sist oppdatert',
        save: () => 'Lagre',
    };
    const biography = {
        about_me: () => 'Om meg',
        about_the_candidate: () => 'Om kandidaten',
        and_n_more: (n) => `og ${n} til`,
        applied_for_license: () => 'Har søkt om autorisasjon',
        biography: () => 'Biografi',
        country: () => 'Land',
        current_employment: () => 'Nåværende ansettelse',
        currently_employed: () => 'For tiden sysselsatt',
        has_applied_for_license_in: (market, hasAnyLicense) => `har søkt ${hasAnyLicense ? 'om autorisasjon ' : ''}i ${$list(market.map($market))}`,
        home_municipality: () => 'Hjemkommune',
        journal_system_other: () => 'Journalsystemer',
        journal_systems: () => 'Journalsystemer',
        licensed: (profession) => profession
            ? `Autorisert ${$profession(profession, 1).toLowerCase()}`
            : 'Har autorisasjon',
        licensed_in: (market) => `autorisert i ${$list(market.map($market))}`,
        n_years_of_experience: (years) => `${years}+ års erfaring`,
        name: () => 'Navn',
        no_biography: () => 'ingen beskrivelse',
        norwegian_license: () => 'Norsk autorisasjon',
        profession: () => 'Yrke',
        send_message: () => 'Send melding',
        specialization: () => 'Spesialisering',
        swedish_license: () => 'Svensk autorisasjon',
        write_a_biography: () => 'Skriv en kort biografi om deg selv...',
        years_of_experience: () => 'Antall års erfaring',
    };
    const candidate_type = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'Booket',
            [enums_1.CandidateType.MANAGED]: 'Opprettet',
            [enums_1.CandidateType.MIRRORED]: 'Kontakt',
            [enums_1.CandidateType.SOVEREIGN]: 'Profil',
            [enums_1.CandidateType.STATIC]: 'ATLAS',
            [enums_1.CandidateType.SYNCED]: 'CRM',
            [enums_1.CandidateType.EMPLOYED]: 'Ansatte',
        };
        return translations[type];
    };
    const candidate_type_action = (type) => {
        const translations = {
            [enums_1.CandidateType.BOOKED]: 'booket',
            [enums_1.CandidateType.MANAGED]: 'lagt til',
            [enums_1.CandidateType.MIRRORED]: 'åpnet',
            [enums_1.CandidateType.SOVEREIGN]: 'oppdatert',
            [enums_1.CandidateType.STATIC]: 'åpnet',
            [enums_1.CandidateType.SYNCED]: 'synkronisert',
            [enums_1.CandidateType.EMPLOYED]: 'lagt til',
        };
        return translations[type];
    };
    const contact_requests = {
        contact_request_other: () => 'Kontaktforespørsler',
        n_new: (count) => `${count} ny${count === 1 ? '' : 'e'}!`,
    };
    const edit = {
        you_are_editing_candidate: (name) => `Du redigerer ${name ?? 'din profil'}.`,
    };
    const file_uploads = {
        documents: () => 'Dokumenter',
        fetched_at: (date) => `Hentet ${date}`,
        upload: () => 'Last opp...',
        upload_document: () => 'Nytt dokument',
        uploaded_at: (date) => `Lastet opp ${date}`,
    };
    const information = {
        add_a_personal_identity_number: () => 'Legg til personnummer',
        add_a_phone_number: () => 'Legg til telefonnummer',
        add_an_email: () => 'Legg til e-post',
        add_authorization_year: () => 'Legg til autorisasjonsår',
        authorized_since: () => 'Autorisert siden',
        authorized_since_placeholder: () => 'Velg år...',
        contact_information: () => 'Kontaktinformasjon',
        email: () => 'E-post',
        email_placeholder: () => 'F.eks. navn@organisasjon.no',
        hpr_number: () => 'HPR-nummer',
        no_information: () => 'Det er ingen informasjon å vise.',
        personal_identity_number: () => 'Personnummer',
        personal_identity_number_placeholder: () => 'YYYYMMDD-XXXX',
        phone_number: () => 'Telefonnummer',
        phone_number_placeholder: () => 'F.eks. +47 21 23 45 67',
    };
    const interests = {
        bemlo_job_matcher: () => 'Bemlo Job Matcher',
        candidate_interests: () => 'Kandidatinteresser',
        country_other: () => 'Land',
        department_other: () => 'Avdelinger',
        earliest_start_date: () => 'Tidligst startdato',
        extent: () => 'Omfang',
        interests: () => 'Interesser',
        job_type: (jobType) => {
            const translations = {
                [enums_1.JobType.IRREGULAR_PARTTIME]: 'Uregelmessige deltidsjobber',
                [enums_1.JobType.JOUR]: 'Jourjobber',
                [enums_1.JobType.PERMANENT]: 'Faste jobber',
                [enums_1.JobType.STAFFING]: 'Bemanningsjobber',
                [enums_1.JobType.TEMPORARY]: 'Midlertidige jobber',
            };
            return translations[jobType];
        },
        job_type_other: () => 'Type ansettelse',
        make_my_contact_information_visible_to_employers: () => 'Gjør kontaktinformasjonen min synlig for arbeidsgivere',
        municipality_other: () => 'Kommuner',
        open_for_work_from: () => 'Åpen for arbeid fra',
        open_to_work_from: (date) => `Åpent for jobber fra ${date}`,
        region_other: () => 'Regioner',
        subscribe_to_matching_jobs: () => 'Abonner på matchende jobber',
        we_will_notify_you_once_a_day: () => 'Vi vil varsle deg en gang om dagen',
        what_jobs_are_you_interested_in: () => 'Hvilke jobber er du interessert i?',
        work_form_other: () => 'Ansettelsesformer',
        you_may_be_contacted_regarding_job_opportunities: () => 'Du kan bli kontaktet angående jobbmuligheter',
    };
    const contract = {
        contract: () => 'Kontrakt',
        default_day_break: () => 'Dagpause',
        default_week_break: () => 'Ukepause',
        employment_type: () => 'Ansettelsesform',
        from: () => 'Fra',
        full_time_hours: () => 'Heltid',
        period: () => 'period',
        scope: () => 'Omfang',
        service_level: () => 'Tjenestenivå',
        shift_hours: () => 'Skifttimer',
        unit_hours_per_week: () => 'tim/uke',
        unit_percentage: () => '%',
        until: () => 'Til',
        working_time_directive: () => 'Arbeidstidsdirektiv',
    };
    const license_status = $licenseStatus;
    const profile_progress = {
        add_authorization: () => 'Legg til Autorisasjon',
        invite_a_reference: () => 'Inviter referanser',
        maximize_your_chances: () => 'Maksimer sjansene dine for å finne drømmejobben.',
        upload_cv: () => 'Last opp CV',
        upload_license: () => 'Last opp Autorisasjon',
        your_profile_is_n_percent_done: (percent) => `Din profil er ${percent}% ferdig`,
        your_profile_looks_empty: () => 'Din profil ser tom ut',
    };
    const references = {
        add_reference: () => 'Legg til referans',
        added: () => 'Lagt til',
        added_at: (when) => `Lagt til ${when}`,
        n_attachments: (attachments) => `${attachments} vedlegg`,
        n_questions_answered: (count) => `${count} spørsmål besvart`,
        no_references: () => 'Det er ingen referanser å vise til.',
        no_references_added: () => 'Det er ingen referanser lagt til ennå.',
        references: () => 'Referanser',
        reported_at: (when, isManaged) => `Rapportert ${isManaged ? '' : 'av kandidaten '}${when}`,
        waiting_for_response: () => `Venter på respons...`,
    };
    const summary_section = {
        locked: () => 'Låst',
    };
    const vacancies = {
        based_on_the_candidates_profile: () => 'Basert på kandidatens profil',
        based_on_your_profile: () => 'Basert på din profil',
        jobs_for_you: () => 'Jobber for deg',
        matching_vacancies: () => 'Matchende jobber',
        n_percent_match: (percent) => `${percent}% match`,
        no_title: () => 'ingen tittel',
        region_other: () => 'Regioner',
        see_n_matching_jobs: (count) => `Se ${count ?? 'alle'} matchende jobber`,
        show_more: () => 'Vis mer',
    };
    return {
        base,
        biography,
        candidate_type,
        candidate_type_action,
        contact_requests,
        contract,
        edit,
        file_uploads,
        information,
        interests,
        license_status,
        profile_progress,
        references,
        summary_section,
        vacancies,
    };
})();
const unit_relation = (type) => {
    const translations = {
        [enums_1.UnitRelation.HOME_UNIT]: 'Min enhet',
        [enums_1.UnitRelation.CROSS_UNIT]: 'Annen enhet',
        [enums_1.UnitRelation.CROSS_ORG]: 'Annen Org.',
    };
    return translations[type];
};
exports.unit_relation = unit_relation;
exports.availability = {
    available: () => 'Tilgjengelig',
    blocked: () => 'Blokkert',
    non_preferred: () => 'Ikke foretrukket',
    scheduled: () => 'Planlagt',
    unavailable: () => 'Utilgjengelig',
    vacation: () => 'Ferie',
};
exports.shifts = {
    absent: () => 'Fraværende',
    accepted: () => 'Akseptert',
    accepting: () => 'Aksepterer',
    add_many: () => 'Legg til mange vakter',
    add_many_description: (everyNWeeks, periodStart) => `Legger til vakter ${everyNWeeks === 1 ? 'hver uke' : `hver ${everyNWeeks}. uke`} fra ${periodStart}`,
    add_many_period_start: () => 'Startperiode',
    add_many_periodicity: () => 'Periodisitet',
    add_many_periodicity_placeholder: () => "F.eks '2' for annenhver uke",
    applied: () => 'Søkt',
    archived: () => 'Arkivert',
    available: () => 'Tilgjengelig',
    blocked: () => 'Blokkert',
    book: () => 'Book',
    booked: () => 'Booket',
    cancelled: () => 'Avlyst',
    day: () => 'Dag',
    draft: () => 'Utkast',
    external_resource_other: () => 'Eksterne ressurser',
    flex_shift: () => 'Fleksibel vakt',
    make_fixed: () => 'Gjør fast',
    new: () => 'Ny',
    new_row: () => 'Ny rad',
    night: () => 'Natt',
    no_shifts: () => 'Ingen skift',
    not_published: () => 'Ikke publisert',
    occupied: () => 'Opptatt',
    presented: () => 'Presentert',
    published: () => 'Publisert',
    reject: () => 'Avvis',
    rejected: () => 'Avvist',
    rejecting: () => 'Avviser',
    remove_fixed: () => 'Fjern fast',
    replace: () => 'Erstatt',
    replacing: () => 'Erstatter',
    required_day: () => 'Obligatorisk dag',
    requires_meds_training: () => 'Requires meds training',
    row_n: (row) => `Rad ${row}`,
    selected: () => 'Valgt',
    shift_one: () => 'Skift',
    shift_other: () => 'Skifte',
    suggested: () => 'Foreslått',
    template: () => 'Mal',
    tendered: () => 'Tilbudt',
    twilight: () => 'Kveld',
    unavailable: () => 'Ikke tilgjengelig',
    unselected: () => 'Ikke valgt',
    urgent: () => 'Haster',
    vacant_one: () => 'Ledig',
    vacant_other: () => 'Ledige',
};
exports.timesheets = {
    attested: () => 'Attestert',
    break_time: () => 'Pause',
    consultant_manager: () => 'Konsulentleder',
    disturbances: () => 'Forstyrrelser',
    end_time: () => 'Sluttid',
    reported: () => 'Rapportert',
    resource: () => 'Ressurs',
    returned: () => 'Returnert',
    start_time: () => 'Starttid',
    time_report_log: () => 'Timelogg',
    unit_manager: () => 'Enhetsleder',
    updated: () => 'Oppdatert',
};
exports.jobs = {
    apply_by: () => 'Søk innen',
    apply_directly_on_the_website: () => 'Søk direkte på Bemlo',
    estimated_salary: () => 'Estimert lønn',
    explore_similar_jobs: () => 'Se lignende jobber',
    full_time_description: () => 'Oppdraget er på 100%',
    gathered_best_jobs_for_you_description: () => 'Vi har samlet bemanningsjobber fra flere bemanningsbyråer, slik at du enkelt kan finne den som passer deg best.',
    irregular_part_time_description: () => 'Oppdraget innbærer enkeltstående vakter nå og da',
    job_location: (municipality) => `Bemanning i ${municipality}`,
    logo_of_employer: () => 'Logo av arbeidsgiver',
    match: () => 'Match',
    new: () => 'Ny!',
    no_description: () => 'Ingen beskrivelse',
    open_map: () => 'Åpne kart',
    published_at_date: (date) => `Publisert ${date}`,
    regular_part_time_description: () => 'Oppdraget innbærer enkeltstående vakter nå og da',
    the_job_is_located_in_municipality: (municipality) => `Oppdraget ligger i ${municipality}`,
    week_number: (week) => `Uke ${week}`,
    your_workplace: () => 'Din arbeidsplass',
};
exports.availability_schedule = {
    schema_error_scope_must_be_greater_than_or_equal_to: (scope) => `Omfang må være større enn eller lik ${scope}.`,
    schema_error_scope_must_be_less_than_or_equal_to: (scope) => `Omfang må være mindre enn eller lik ${scope}.`,
    schema_error_select_at_least_one_day: () => 'Velg minst én dag.',
    schema_error_select_at_least_one_shift_hours_type: () => 'Velg minst én skifttype.',
};

import { ReactNode, createContext, useContext } from 'react'

export type TableProps = {
  bordered?: boolean
  children?: ReactNode
  hoverable?: boolean
  responsive?: boolean
  striped?: boolean
  noBoxShadow?: boolean
}

export const TablePropsContext = createContext<TableProps | null>(null)

export const useTableProps = () => {
  const context = useContext(TablePropsContext)
  if (!context) {
    throw new Error(
      'useTableContext must be used within the TableContext.Provider',
    )
  }
  return context
}

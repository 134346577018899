"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vacancies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const schedule_1 = require("@bemlo/schedule");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const NewShift_1 = require("../components/NewShift");
const Row_1 = require("../components/Row");
const VacanciesTitle_1 = require("../components/VacanciesTitle");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
// DRAFT is handled separately
// TODO: add BOOKED if compact mode is enabled
const CELL_STATUSES = [
    'ARCHIVED',
    'VACANT',
    'APPLIED',
    'SUGGESTED',
    'PRESENTED',
    'ACCEPTED',
    'BOOKED',
];
const toId = (value) => value.id;
const Vacancies = (props) => {
    const { startDate, days, currentWeek, shifts: allShifts, shiftPopover, isScrolled, } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onCreateDraftShift, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(props);
    const shifts = allShifts.filter((0, by_filter_1.byCellFilter)(props));
    const shiftsToDisplay = props.includeBooked
        ? shifts
        : shifts.filter(({ bookedCandidateId }) => !bookedCandidateId);
    const shiftsInWeek = currentWeek
        ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, shiftsToDisplay)
        : shiftsToDisplay;
    if (!shifts.length && !enableEditing && !props.persisted)
        return null;
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, rightShadow: isScrolled, children: (0, jsx_runtime_1.jsx)(VacanciesTitle_1.VacanciesTitle, { ...props, shiftHours: shiftsInWeek[0] ? (0, schedule_1.getShiftHours)(shiftsInWeek[0]) : null, startDate: startDate, persisted: props.persisted, shiftIds: shiftsInWeek.map(toId) }) }), days.map((day) => {
                const shiftsOnDay = (0, lodash_1.groupBy)(shiftsToDisplay.filter((shift) => shift.date === day.dateISO), 'status');
                const draftShifts = shiftsOnDay['DRAFT'] ?? [];
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: !day.withinRange, weekend: day.isWeekend, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [CELL_STATUSES.map((status) => {
                                const shifts = shiftsOnDay[status] ?? [];
                                const isArchived = status === 'ARCHIVED';
                                const isUrgent = shifts.every((shift) => shift.isUrgent) && !isArchived;
                                if (!shifts.length)
                                    return null;
                                return ((0, jsx_runtime_1.jsx)(NewShift_1.NewShift, { shift: shifts[0], interestedCandidates: shifts[0].interestedCandidates, popoverContent: shiftPopover, shiftIds: shifts.map(toId), status: status, isUrgent: isUrgent, isPublished: shifts.some((s) => s.isPublished), isTendered: shifts.some((s) => s.isTendered), unselectable: isArchived }, status));
                            }), enableEditing && day.withinRange && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShifts, { showBadges: true, shifts: draftShifts, onClickNewShift: () => onCreateDraftShift(day.dateISO, shifts[0]?.scheduleSlotId), onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateISO));
            })] }));
};
exports.Vacancies = Vacancies;

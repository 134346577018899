"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShiftCardColors = exports.STATUS_COLOR_MAP = void 0;
const enums_1 = require("@bemlo/enums");
const constants_1 = require("../../constants");
exports.STATUS_COLOR_MAP = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: {
        text: constants_1.Color.DARK_RED,
        borderOuter: constants_1.Color.DARK_RED,
        borderInner: constants_1.Color.TRANSPARENT_RED,
        background: constants_1.Color.LIGHTER_RED,
    },
    [enums_1.DynamicShiftStatus.AVAILABLE]: {
        text: constants_1.Color.BLUE,
        borderOuter: constants_1.Color.BLUE,
        borderInner: constants_1.Color.TRANSPARENT_BLUE,
        background: constants_1.Color.LIGHTER_BLUE,
    },
    [enums_1.DynamicShiftStatus.BOOKED]: {
        text: constants_1.Color.DARKER_GREEN,
        borderOuter: constants_1.Color.DARK_GREEN,
        borderInner: constants_1.Color.LIGHTER_BLUE,
        background: constants_1.Color.TRANSPARENT_GREEN,
    },
    [enums_1.DynamicShiftStatus.CANCELED]: {
        text: constants_1.Color.DARK_GREY,
        borderOuter: constants_1.Color.DARK_GREY,
        borderInner: constants_1.Color.MEDIUM_GREY,
        background: constants_1.Color.LIGHT_GREY,
    },
    [enums_1.DynamicShiftStatus.APPLIED]: {
        text: constants_1.Color.DARK_YELLOW,
        borderOuter: constants_1.Color.DARK_YELLOW,
        borderInner: constants_1.Color.YELLOW,
        background: constants_1.Color.LIGHT_YELLOW,
    },
    [enums_1.DynamicShiftStatus.REJECTED]: {
        text: constants_1.Color.DARK_RED,
        borderOuter: constants_1.Color.DARK_RED,
        borderInner: constants_1.Color.TRANSPARENT_RED,
        background: constants_1.Color.LIGHTER_RED,
    },
    [enums_1.DynamicShiftStatus.OCCUPIED]: {
        text: constants_1.Color.DARK_GREY,
        borderOuter: constants_1.Color.DARK_GREY,
        borderInner: constants_1.Color.MEDIUM_GREY,
        background: constants_1.Color.LIGHT_GREY,
    },
    [enums_1.DynamicShiftStatus.WITHDRAWN]: {
        text: constants_1.Color.GOLDEN_ROD,
        borderOuter: constants_1.Color.GOLDEN_ROD,
        borderInner: constants_1.Color.GOLDEN_ROD,
        background: constants_1.Color.LIGHT_YELLOW,
    },
    [enums_1.DynamicShiftStatus.SUGGESTED]: {
        text: constants_1.Color.DARK_YELLOW,
        borderOuter: constants_1.Color.GOLDEN_ROD,
        borderInner: constants_1.Color.YELLOW,
        background: constants_1.Color.YELLOW,
    },
    [enums_1.DynamicShiftStatus.ACCEPTED]: {
        text: constants_1.Color.CHARCOAL,
        borderOuter: constants_1.Color.GOLDEN_ROD,
        borderInner: constants_1.Color.YELLOW,
        background: constants_1.Color.YELLOW,
    },
};
const useShiftCardColors = (status, isUrgent) => {
    const statusColor = exports.STATUS_COLOR_MAP[status];
    return {
        ...statusColor,
        statusBorder: isUrgent ? constants_1.Color.RED : statusColor.text,
    };
};
exports.useShiftCardColors = useShiftCardColors;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRangeTypes = exports.ALL_RANGE_TYPES = void 0;
const react_1 = require("react");
const utils_1 = require("@bemlo/utils");
const translate_1 = require("../../translate");
exports.ALL_RANGE_TYPES = (0, utils_1.arrayOfAll)()([
    'custom',
    'month',
    'two-weeks',
    'week',
]);
Object.freeze(exports.ALL_RANGE_TYPES);
const useRangeTypes = (selected, enabled = exports.ALL_RANGE_TYPES) => {
    const t = (0, translate_1.useTranslations)();
    const options = [
        {
            value: 'week',
            label: t.form.week(),
            todayLabel: t.common.this_week(),
            getToday: () => {
                const startDate = t.$date().startOf('week');
                const endDate = startDate.endOf('week');
                return [startDate, endDate];
            },
            getFirstDate: (date) => date.startOf('week'),
            getLastDate: (date) => date.endOf('week'),
            getMovedDate: (date, delta) => date.add(delta, 'week'),
            getLabel: (date) => date.formatWeek(),
        },
        {
            value: 'two-weeks',
            label: t.form.n_weeks(2),
            todayLabel: t.common.this_week(),
            getToday: () => {
                const startDate = t.$date().startOf('week');
                const endDate = startDate.add(1, 'week').endOf('week');
                return [startDate, endDate];
            },
            getFirstDate: (date) => date.startOf('week'),
            getLastDate: (date) => date.endOf('week').add(1, 'week'),
            getMovedDate: (date, delta) => date.add(delta * 2, 'week'),
            getLabel: (date) => date.formatWeekRange(date.add(1, 'week'), true),
        },
        {
            value: 'month',
            label: t.form.month(),
            todayLabel: t.common.this_month(),
            getToday: () => {
                const startDate = t.$date().startOf('week');
                const endDate = startDate.endOf('month');
                return [startDate, endDate];
            },
            getFirstDate: (date) => date.startOf('month'),
            getLastDate: (date) => date.endOf('month'),
            getMovedDate: (date, delta) => date.add(delta, 'month'),
            getLabel: (date) => date.format('MMMM YYYY'),
        },
        {
            value: 'custom',
            label: t.form.custom(),
            todayLabel: t.common.today(),
            getToday: undefined,
            getFirstDate: undefined,
            getLastDate: undefined,
            getMovedDate: undefined,
            getLabel: undefined,
        },
    ];
    const selectedOption = options.find((option) => option.value === selected);
    return (0, react_1.useMemo)(() => {
        return {
            options: options.filter((option) => enabled.includes(option.value)),
            selectedOption,
        };
    }, [selected, t, enabled]);
};
exports.useRangeTypes = useRangeTypes;

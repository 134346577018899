"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslations = exports.useLocale = exports.I18nProvider = exports.I18nContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const zod_1 = require("zod");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const i18n_1 = require("@bemlo/i18n");
const schedule_1 = require("@bemlo/schedule");
const en = __importStar(require("./locales/en"));
const nb = __importStar(require("./locales/nb"));
const sv = __importStar(require("./locales/sv"));
const currency_1 = require("./mappers/currency");
const zod_error_map_1 = require("./mappers/zod-error-map");
const DEFAULT_LOCALE = enums_1.Locale.SV;
const locales = {
    da: en, // TODO(rb): add Danish translations
    fi: en, // TODO(rb): add Finnish translations
    en,
    nb,
    sv,
};
exports.I18nContext = (0, react_1.createContext)({
    locale: DEFAULT_LOCALE,
    setLocale: () => undefined,
});
const I18nProvider = ({ getDefaultLocale, children, }) => {
    const [locale, setLocale] = (0, react_1.useState)(getDefaultLocale);
    const value = (0, react_1.useMemo)(() => {
        return {
            locale,
            setLocale: (newLocale) => {
                window.localStorage.setItem('locale', newLocale);
                setLocale(newLocale);
            },
        };
    }, [locale]);
    (0, react_1.useEffect)(() => {
        const formTranslations = (0, i18n_1.getTranslation)('form', locale);
        zod_1.z.setErrorMap((0, zod_error_map_1.translateZodErrorMap)(formTranslations));
    }, [locale]);
    return (0, jsx_runtime_1.jsx)(exports.I18nContext.Provider, { value: value, children: children });
};
exports.I18nProvider = I18nProvider;
const useLocale = () => {
    const { locale } = (0, react_1.useContext)(exports.I18nContext);
    return locale;
};
exports.useLocale = useLocale;
const useTranslations = () => {
    const locale = (0, exports.useLocale)();
    return (0, react_1.useMemo)(() => {
        return {
            ...locales[locale],
            $locale: locale,
            $date: (0, date_1.dayjsFactory)(locale),
            $n_hours: (0, i18n_1.translateNHoursAndMinutesFactory)(locale),
            $country: (0, i18n_1.translateCountryFactory)(locale),
            $currentEmploymentStatus: (0, i18n_1.translateCurrentEmploymentStatusFactory)(locale),
            $driversLicense: (0, i18n_1.translateDriversLicenseFactory)(locale),
            $employmentType: (0, i18n_1.translateEmploymentTypeFactory)(locale),
            $fileUploadType: (0, i18n_1.translateFileUploadTypeFactory)(locale),
            $howMuchWorkAlternative: (0, i18n_1.translateHowMuchWorkFactory)(locale),
            $jobType: (0, i18n_1.translateJobTypeFactory)(locale),
            $journalSystem: (0, i18n_1.translateJournalSystemFactory)(locale),
            $municipality: (0, i18n_1.translateMunicipalityFactory)(locale),
            $profession: (0, i18n_1.translateProfessionFactory)(locale),
            $professionShort: (0, i18n_1.translateProfessionShortFactory)(locale),
            $shiftActivity: (0, i18n_1.translateShiftActivityFactory)(locale),
            $shiftHours: (0, i18n_1.translateShiftHoursFactory)(locale),
            $shiftType: (0, i18n_1.translateShiftTypeFactory)(locale),
            $specialization: (0, i18n_1.translateSpecializationTitleFactory)(locale),
            $specializationEducation: (0, i18n_1.translateSpecializationEducationFactory)(locale),
            $tenderPricing: (0, i18n_1.translateTenderPricingFactory)(locale),
            $tenderScheduleType: (0, i18n_1.translateTenderStatusFactory)(locale),
            $tenderStatus: (0, i18n_1.translateTenderStatusFactory)(locale),
            $howMuchWork: (0, i18n_1.translateHowMuchWorkFactory)(locale),
            $whichDepartmentToWorkInAlternative: (0, i18n_1.translateWhichDepartmentToWorkInAlternativeFactory)(locale),
            $whichRegionAlternative: (0, i18n_1.translateWhichRegionAlternativeFactory)(locale),
            $workForm: (0, i18n_1.translateWorkFormFactory)(locale),
            $workHours: (0, i18n_1.translateWorkHoursFactory)(locale),
            $workPlaceRelationshipToApplicant: (0, i18n_1.translateWorkPlaceRelationshipToApplicantFactory)(locale),
            $dynamicShiftStatus: (0, i18n_1.translateDynamicShiftStatusFactory)(locale),
            $schedulingRuleViolation: (0, schedule_1.translateSchedulingRuleViolationFactory)(locale),
            $currency: (0, currency_1.formatCurrency)(locale),
            $currencyRange: (0, currency_1.formatCurrencyRange)(locale),
            $onCallTypeAbbreviation: (0, i18n_1.translateOnCallTypeFactory)(locale, 'abbreviation'),
            form: (0, i18n_1.getTranslation)('form', locale),
            ui: (0, i18n_1.getTranslation)('ui', locale),
        };
    }, [locale]);
};
exports.useTranslations = useTranslations;

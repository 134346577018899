import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { useTableProps } from './useTableProps'
import { Color, theme, mediaBreakpointGte } from '../imports'

export type TableRowElementProps = {
  backgroundColor?: string
  head?: boolean
  hoverable?: boolean
  responsive?: boolean
  selected?: boolean
  striped?: boolean
}

export type TableRowProps = Omit<
  TableRowElementProps,
  'hoverable' | 'responsive' | 'striped'
> & {
  children?: ReactNode
  className?: string
  /**
   * @deprecated
   */
  hoverable?: boolean
  /**
   * @deprecated
   */
  responsive?: boolean
  /**
   * @deprecated
   */
  striped?: boolean
  onClick?: () => void
}

const TableRowElement = styled.tr<TableRowProps>((props) => ({
  // NOTE: We need a background color for the `hoverable` prop to work properly:
  backgroundColor: props.selected
    ? Color.LIGHTER_BLUE
    : (props.backgroundColor ?? Color.WHITE),
  boxShadow: props.selected ? `0 0 0 1px ${Color.LIGHT_BLUE} inset` : undefined,
  cursor: props.hoverable ? 'pointer' : undefined,
  transition: `background-color ${theme.animation.transition}`,
  userSelect: props.hoverable ? 'none' : undefined,
  '&:first-of-type': {
    '& > :first-of-type': {
      borderTopLeftRadius: theme.border.radius.normal - 1,
    },
    '& > :last-of-type': {
      borderTopRightRadius: theme.border.radius.normal - 1,
    },
  },
  '&:last-of-type': {
    '& > :first-of-type': {
      borderBottomLeftRadius: theme.border.radius.normal - 1,
    },
    '& > :last-of-type': {
      borderBottomRightRadius: theme.border.radius.normal - 1,
    },
  },
  '&:nth-of-type(2n)': {
    backgroundColor: props.selected
      ? Color.LIGHTER_BLUE
      : props.striped
        ? (props.backgroundColor ?? Color.LIGHTEST_BLUE)
        : undefined,
  },
  ':hover': props.hoverable
    ? {
        backgroundColor: props.backgroundColor,
        filter: 'brightness(95%)',
      }
    : undefined,
  ...(props.responsive
    ? {
        alignItems: 'flex-start',
        borderBottom: theme.border.style.thin,
        display: props.head ? 'none' : 'flex',
        flexDirection: 'column',
        paddingBottom: 12,
        paddingTop: 12,
        [mediaBreakpointGte('md')]: {
          alignItems: 'normal',
          borderBottom: 'none',
          display: 'table-row',
          flexDirection: 'row',
          paddingBottom: 0,
          paddingTop: 0,
        },
      }
    : {}),
}))

/**
 * TODO: Fix bottom row border radius on hover.
 */
export const TableRow = (props: TableRowProps) => {
  const tableProps = useTableProps()
  const hoverable = tableProps.hoverable ?? props.hoverable
  const responsive = tableProps.responsive ?? props.responsive
  const striped = tableProps.striped ?? props.striped

  return (
    <TableRowElement
      backgroundColor={props.backgroundColor}
      className={props.className}
      head={props.head}
      hoverable={hoverable}
      responsive={responsive}
      selected={props.selected}
      striped={striped}
      onClick={props.onClick}
    >
      {props.children}
    </TableRowElement>
  )
}

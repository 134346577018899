import styled from '@emotion/styled'

import { SpaceProps, space } from './imports'

export type ContainerProps = SpaceProps & {
  /**
   * @deprecated use space props
   */
  noPadding?: boolean
  thick?: boolean
  wide?: boolean
}

export const Container = styled.div<ContainerProps>((props) => {
  return {
    boxSizing: 'border-box',
    maxWidth: (props.wide ? 1280 : 1088) - (props.thick ? 32 : 0),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: props.noPadding ? 0 : props.thick ? 8 : 24,
    paddingRight: props.noPadding ? 0 : props.thick ? 8 : 24,
    ...space(props),
    position: 'relative',
    width: '100%',
  }
})

import './layout.css'

import Cookies from 'js-cookie'
import { ReactNode, useEffect, useState } from 'react'

import { ApolloProvider } from './ApolloProvider'
import { AuthContextProvider } from './context/AuthContext'
import { CompaniesContextProvider } from './context/CompaniesContext'
import { CookiePreferenceContextProvider } from './context/CookiePreferenceContext'
import { UserContextProvider } from './context/UserContext'
import { CookieConsent } from './CookieConsent'
import { Footer } from './Footer'
import { NoSSR } from './NoSSR'
import { I18nProvider } from '../ui'
import { locale } from '../translations/locales/__LOCALE__'

const updateUtmCookies = (params: URLSearchParams) => {
  const cookiesToUpdate = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
  ]
  const currentUtmValues: { [key: string]: string } = {}
  cookiesToUpdate.forEach((cookie) => {
    const currentCookie = Cookies.get(cookie)
    if (!currentCookie || currentCookie === 'null') {
      const value = params.get(cookie) || ''
      Cookies.set(cookie, value)
      currentUtmValues[cookie] = value
    } else {
      currentUtmValues[cookie] = currentCookie
    }
  })
  return currentUtmValues
}

type LayoutProps = {
  children: ReactNode
  location: Location
}

const Layout = ({ children, location }: LayoutProps) => {
  const [utmValues, setUtmValues] = useState<{
    [key: string]: string
  }>({})

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setUtmValues(updateUtmCookies(params))
  }, [])

  const path = (location.pathname || '').replace(/\//g, '')
  const avoidNewsletterModal = ['cv', 'jobb'].includes(path)
  const avoidCookieConsent = ['cookies'].includes(path)

  return (
    <I18nProvider getDefaultLocale={() => locale}>
      <ApolloProvider>
        <UserContextProvider>
          <AuthContextProvider utmValues={utmValues}>
            <CompaniesContextProvider>
              <NoSSR>
                <CookiePreferenceContextProvider>
                  {!avoidCookieConsent && <CookieConsent />}
                </CookiePreferenceContextProvider>
              </NoSSR>
              <div className="body-content">
                <div id="modal-root" />
                <div className="content">{children}</div>
                {!avoidNewsletterModal && <Footer />}
              </div>
            </CompaniesContextProvider>
          </AuthContextProvider>
        </UserContextProvider>
      </ApolloProvider>
    </I18nProvider>
  )
}

export default Layout

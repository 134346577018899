"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedVacancies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = require("react");
const schedule_1 = require("@bemlo/schedule");
const translate_1 = require("../../../../translate");
const by_filter_1 = require("../by-filter");
const Cell_1 = require("../components/Cell");
const CellShiftsColumn_1 = require("../components/CellShiftsColumn");
const DraftShifts_1 = require("../components/DraftShifts");
const NewShift_1 = require("../components/NewShift");
const ResourceTitle_1 = require("../components/ResourceTitle");
const Row_1 = require("../components/Row");
const ShiftError_1 = require("../components/ShiftError");
const useEditShifts_1 = require("../useEditShifts");
const useSchedule_1 = require("../useSchedule");
const get_shifts_in_week_1 = require("../utils/get-shifts-in-week");
const FixedVacancies = (props) => {
    const t = (0, translate_1.useTranslations)();
    const { days, currentWeek, shifts: allShifts, shiftPopover } = (0, useSchedule_1.useSchedule)();
    const { enableEditing, onCreateDraftShift, onDeleteDraftShift } = (0, useEditShifts_1.useEditShifts)(props.draftShiftInput);
    const [shifts, shiftsToDisplay, shiftsInWeek, dayErrors] = (0, react_1.useMemo)(() => {
        const shifts = allShifts.filter((0, by_filter_1.byCellFilter)(props));
        const shiftsToDisplay = shifts.filter(({ bookedCandidateId }) => !bookedCandidateId);
        const shiftsInWeek = currentWeek
            ? (0, get_shifts_in_week_1.getShiftsInWeek)(currentWeek, shifts)
            : shiftsToDisplay;
        const dayErrors = props.schedulingRules
            ? (0, schedule_1.getRuleViolationsMap)(props.schedulingRules.ruleSets, shiftsToDisplay, props.schedulingRules.rules, props.schedulingRules.resource)
            : {};
        return [shifts, shiftsToDisplay, shiftsInWeek, dayErrors];
    }, [allShifts, props, currentWeek]);
    return ((0, jsx_runtime_1.jsxs)(Row_1.Row, { children: [(0, jsx_runtime_1.jsx)(Cell_1.Cell, { isTitle: true, rowSpan: 1, children: (0, jsx_runtime_1.jsx)(ResourceTitle_1.ResourceTitle, { title: t.shifts.row_n(props.rowKey + 1), shifts: shiftsInWeek, bold: false }) }), days.map((day) => {
                const [draftShifts, shiftsOnDay] = (0, lodash_1.partition)(shiftsToDisplay.filter((shift) => shift.date === day.dateISO), ({ status }) => status === 'DRAFT');
                const hasShifts = !!draftShifts.length || !!shiftsOnDay.length;
                const errorsOnDay = dayErrors[day.dateISO]?.map((error) => ({
                    error: t.$schedulingRuleViolation(error),
                    isWarning: error.severity === 'warning',
                })) ?? [];
                return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { disabled: !day.withinRange, weekend: day.isWeekend, children: (0, jsx_runtime_1.jsxs)(CellShiftsColumn_1.CellShiftsColumn, { children: [shiftsOnDay.map((shift) => {
                                const isArchived = shift.status === 'ARCHIVED';
                                const isUrgent = shift.isUrgent && !isArchived;
                                if (!shift)
                                    return null;
                                return ((0, jsx_runtime_1.jsx)(NewShift_1.NewShift, { shift: shift, interestedCandidates: shift.interestedCandidates, popoverContent: shiftPopover, shiftIds: [shift.id], status: shift.status, isUrgent: isUrgent, isPublished: shift.isPublished, isTendered: shift.isTendered, unselectable: isArchived, error: (0, jsx_runtime_1.jsx)(ShiftError_1.ShiftError, { errors: errorsOnDay }) }, shift.id));
                            }), enableEditing && day.withinRange && ((0, jsx_runtime_1.jsx)(DraftShifts_1.DraftShifts, { showBadges: true, shifts: draftShifts, onClickNewShift: hasShifts && !props.multipleShifts
                                    ? undefined
                                    : props.draftShiftInput
                                        ? () => onCreateDraftShift(day.dateISO, shifts[0]?.scheduleSlotId)
                                        : props.onClickNewShiftType, onDeleteDraftShift: onDeleteDraftShift }))] }) }, day.dateISO));
            })] }));
};
exports.FixedVacancies = FixedVacancies;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShiftStatusIconName = void 0;
const enums_1 = require("@bemlo/enums");
const STATUS_ICON_MAP = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: 'XCircleIcon',
    [enums_1.DynamicShiftStatus.APPLIED]: 'ReplyIcon',
    [enums_1.DynamicShiftStatus.SUGGESTED]: 'LightBulbIcon',
    [enums_1.DynamicShiftStatus.ACCEPTED]: null,
    [enums_1.DynamicShiftStatus.AVAILABLE]: null,
    [enums_1.DynamicShiftStatus.BOOKED]: 'CheckCircleIcon',
    [enums_1.DynamicShiftStatus.CANCELED]: 'XCircleIcon',
    [enums_1.DynamicShiftStatus.OCCUPIED]: 'XCircleIcon',
    [enums_1.DynamicShiftStatus.REJECTED]: 'ReplyIcon',
    [enums_1.DynamicShiftStatus.WITHDRAWN]: null,
};
const useShiftStatusIconName = (status, isUrgent) => {
    return status === enums_1.DynamicShiftStatus.AVAILABLE && isUrgent
        ? 'ExclamationIcon'
        : STATUS_ICON_MAP[status];
};
exports.useShiftStatusIconName = useShiftStatusIconName;

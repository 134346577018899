"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortenFullName = void 0;
const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
const shortenFullName = (fullName) => {
    if (!fullName || !fullName.trim()) {
        return '';
    }
    const parts = fullName.trim().split(/\s+/).filter(Boolean);
    if (parts.length === 1) {
        // Just one name — return it capitalized
        return capitalize(parts[0]);
    }
    const lastName = capitalize(parts[parts.length - 1]);
    const initials = parts
        .slice(0, -1)
        .map((name) => name.charAt(0).toUpperCase() + '.')
        .join('');
    return `${initials} ${lastName}`;
};
exports.shortenFullName = shortenFullName;

import styled from '@emotion/styled'

import { Color, theme, mediaBreakpointGte, SpaceProps, space } from './imports'

/**
 * @deprecated use Form.Label
 */
export const Label = styled.label<SpaceProps>((props) => ({
  ...space(props),
  color: Color.DARKEST_BLUE,
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.font.size[5],
  fontWeight: theme.font.weight.bold,
  gap: 8,
  width: '100%',
  [mediaBreakpointGte('md')]: {
    maxWidth: 640,
    width: 'auto',
  },
}))

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftHours = exports.day = exports.shiftBookingCancellationReason = exports.shiftActivity = exports.shiftType = exports.onCallTypeAbbreviation = exports.onCallTypeShort = exports.onCallType = exports.howMuchWork = exports.workPlaceRelationshipToApplicant = exports.workHours = exports.workForm = exports.whichRegionAlternative = exports.whichDepartmentToWorkInAlternative = exports.unitType = exports.ui = exports.tenderWaiverStatus = exports.tenderStatus = exports.tenderPricing = exports.specialization = exports.profession = exports.schedulingViolationType = exports.licenseStatus = exports.broadcastMessageRecipientGroup = exports.locale = exports.journalSystem = exports.jobType = exports.howMuchExperience = exports.form = exports.fileUploadType = exports.employmentTypePlural = exports.employmentType = exports.driversLicense = exports.dayjs = exports.currentEmploymentStatus = exports.dynamicShiftStatus = exports.country = void 0;
const which_region_alternative_1 = require("./which-region-alternative");
const which_region_alternative_2 = require("../nb/which-region-alternative");
const which_region_alternative_3 = require("../sv/which-region-alternative");
var country_1 = require("./country");
Object.defineProperty(exports, "country", { enumerable: true, get: function () { return country_1.country; } });
var dynamic_shift_status_1 = require("./dynamic-shift-status");
Object.defineProperty(exports, "dynamicShiftStatus", { enumerable: true, get: function () { return dynamic_shift_status_1.dynamicShiftStatus; } });
var current_employment_status_1 = require("./current-employment-status");
Object.defineProperty(exports, "currentEmploymentStatus", { enumerable: true, get: function () { return current_employment_status_1.currentEmploymentStatus; } });
var dayjs_1 = require("./dayjs");
Object.defineProperty(exports, "dayjs", { enumerable: true, get: function () { return dayjs_1.dayjs; } });
var drivers_license_1 = require("./drivers-license");
Object.defineProperty(exports, "driversLicense", { enumerable: true, get: function () { return drivers_license_1.driversLicense; } });
var employment_type_1 = require("./employment-type");
Object.defineProperty(exports, "employmentType", { enumerable: true, get: function () { return employment_type_1.employmentType; } });
Object.defineProperty(exports, "employmentTypePlural", { enumerable: true, get: function () { return employment_type_1.employmentTypePlural; } });
var file_upload_type_1 = require("./file-upload-type");
Object.defineProperty(exports, "fileUploadType", { enumerable: true, get: function () { return file_upload_type_1.fileUploadType; } });
var form_1 = require("./form");
Object.defineProperty(exports, "form", { enumerable: true, get: function () { return form_1.form; } });
var how_much_experience_1 = require("./how-much-experience");
Object.defineProperty(exports, "howMuchExperience", { enumerable: true, get: function () { return how_much_experience_1.howMuchExperience; } });
var job_type_1 = require("./job-type");
Object.defineProperty(exports, "jobType", { enumerable: true, get: function () { return job_type_1.jobType; } });
var journal_system_1 = require("./journal-system");
Object.defineProperty(exports, "journalSystem", { enumerable: true, get: function () { return journal_system_1.journalSystem; } });
var locale_1 = require("./locale");
Object.defineProperty(exports, "locale", { enumerable: true, get: function () { return locale_1.locale; } });
var broadcast_message_recipient_group_1 = require("./broadcast-message-recipient-group");
Object.defineProperty(exports, "broadcastMessageRecipientGroup", { enumerable: true, get: function () { return broadcast_message_recipient_group_1.broadcastMessageRecipientGroup; } });
var license_status_1 = require("./license-status");
Object.defineProperty(exports, "licenseStatus", { enumerable: true, get: function () { return license_status_1.licenseStatus; } });
var scheduling_violation_type_1 = require("./scheduling-violation-type");
Object.defineProperty(exports, "schedulingViolationType", { enumerable: true, get: function () { return scheduling_violation_type_1.schedulingViolationType; } });
var profession_1 = require("./profession");
Object.defineProperty(exports, "profession", { enumerable: true, get: function () { return profession_1.profession; } });
var specialization_1 = require("./specialization");
Object.defineProperty(exports, "specialization", { enumerable: true, get: function () { return specialization_1.specialization; } });
var tender_pricing_1 = require("./tender-pricing");
Object.defineProperty(exports, "tenderPricing", { enumerable: true, get: function () { return tender_pricing_1.tenderPricing; } });
var tender_status_1 = require("./tender-status");
Object.defineProperty(exports, "tenderStatus", { enumerable: true, get: function () { return tender_status_1.tenderStatus; } });
var tender_waiver_status_1 = require("./tender-waiver-status");
Object.defineProperty(exports, "tenderWaiverStatus", { enumerable: true, get: function () { return tender_waiver_status_1.tenderWaiverStatus; } });
var ui_1 = require("./ui");
Object.defineProperty(exports, "ui", { enumerable: true, get: function () { return ui_1.ui; } });
var unit_type_1 = require("./unit-type");
Object.defineProperty(exports, "unitType", { enumerable: true, get: function () { return unit_type_1.unitType; } });
var which_department_to_work_in_alternative_1 = require("./which-department-to-work-in-alternative");
Object.defineProperty(exports, "whichDepartmentToWorkInAlternative", { enumerable: true, get: function () { return which_department_to_work_in_alternative_1.whichDepartmentToWorkInAlternative; } });
exports.whichRegionAlternative = {
    ...which_region_alternative_2.norwayWhichRegionAlternative,
    ...which_region_alternative_3.swedenWhichRegionAlternative,
    ...which_region_alternative_1.specialWhichRegionAlternative,
    ...which_region_alternative_1.unitedKingdomWhichRegionAlternative,
};
var work_form_1 = require("./work-form");
Object.defineProperty(exports, "workForm", { enumerable: true, get: function () { return work_form_1.workForm; } });
var work_hours_1 = require("./work-hours");
Object.defineProperty(exports, "workHours", { enumerable: true, get: function () { return work_hours_1.workHours; } });
var workplace_relationship_to_applicant_1 = require("./workplace-relationship-to-applicant");
Object.defineProperty(exports, "workPlaceRelationshipToApplicant", { enumerable: true, get: function () { return workplace_relationship_to_applicant_1.workPlaceRelationshipToApplicant; } });
var how_much_work_1 = require("./how-much-work");
Object.defineProperty(exports, "howMuchWork", { enumerable: true, get: function () { return how_much_work_1.howMuchWork; } });
var on_call_type_1 = require("./on-call-type");
Object.defineProperty(exports, "onCallType", { enumerable: true, get: function () { return on_call_type_1.onCallType; } });
Object.defineProperty(exports, "onCallTypeShort", { enumerable: true, get: function () { return on_call_type_1.onCallTypeShort; } });
Object.defineProperty(exports, "onCallTypeAbbreviation", { enumerable: true, get: function () { return on_call_type_1.onCallTypeAbbreviation; } });
var shift_type_1 = require("./shift-type");
Object.defineProperty(exports, "shiftType", { enumerable: true, get: function () { return shift_type_1.shiftType; } });
var shift_activity_1 = require("./shift-activity");
Object.defineProperty(exports, "shiftActivity", { enumerable: true, get: function () { return shift_activity_1.shiftActivity; } });
var shift_booking_cancellation_reason_1 = require("./shift-booking-cancellation-reason");
Object.defineProperty(exports, "shiftBookingCancellationReason", { enumerable: true, get: function () { return shift_booking_cancellation_reason_1.shiftBookingCancellationReason; } });
var day_1 = require("./day");
Object.defineProperty(exports, "day", { enumerable: true, get: function () { return day_1.day; } });
var shift_hours_1 = require("./shift-hours");
Object.defineProperty(exports, "shiftHours", { enumerable: true, get: function () { return shift_hours_1.shiftHours; } });

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigitInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const zod_1 = require("zod");
const Input_1 = require("./Input");
const translate_1 = require("../../translate");
// Updated function to validate numbers, supporting decimals if 'decimals' is true
const isValidNumber = (value, decimals) => {
    if (decimals) {
        // Regex to match numbers with optional minus sign, digits, optional decimal separator, and digits
        return /^-?\d*(?:[.,]\d*)?$/.test(value);
    }
    else {
        // Regex to match integers with optional minus sign
        return /^-?\d+$/.test(value);
    }
};
const DigitInput = (props) => {
    const t = (0, translate_1.useTranslations)();
    const [value, setValue] = (0, react_1.useState)(props.value != null ? props.value.toString() : undefined);
    const schema = zod_1.z
        .string()
        .refine((value) => value === '' || (value && isValidNumber(value, props.decimals)), {
        message: t.form.must_be_a_number(),
    })
        .superRefine((value, ctx) => {
        if (!props.schema)
            return true;
        if (value && !isValidNumber(value, props.decimals))
            return false;
        const normalizedValue = value.replace(',', '.');
        const numberValue = props.decimals
            ? parseFloat(normalizedValue)
            : parseInt(normalizedValue, 10);
        const parseResult = props.schema.safeParse(numberValue);
        if (!parseResult.success) {
            const issue = {
                code: zod_1.z.ZodIssueCode.custom,
                path: [],
                message: parseResult.error.issues[0].message,
            };
            ctx.addIssue(issue);
            return false;
        }
        return true;
    });
    const handlePassValue = (value = '') => {
        if (isValidNumber(value, props.decimals)) {
            const normalizedValue = value.replace(',', '.');
            const numberValue = props.decimals
                ? parseFloat(normalizedValue)
                : parseInt(normalizedValue, 10);
            props.onChange?.(numberValue);
        }
        else {
            props.onChange?.(undefined);
        }
    };
    const handleValueChange = (inputValue) => {
        let parsedValue = inputValue || '';
        if (props.decimals) {
            // Remove invalid characters but allow one decimal separator ('.' or ',') and a minus sign
            parsedValue = parsedValue.replace(/[^\d.,-]/g, '');
            // Ensure only one minus sign at the start
            parsedValue = parsedValue.replace(/(?!^)-/g, '');
            // Ensure only one decimal separator
            const decimalSeparatorMatch = parsedValue.match(/[.,]/g);
            if (decimalSeparatorMatch && decimalSeparatorMatch.length > 1) {
                // Keep only the first decimal separator
                const firstSeparatorIndex = parsedValue.search(/[.,]/);
                parsedValue =
                    parsedValue.slice(0, firstSeparatorIndex + 1) +
                        parsedValue.slice(firstSeparatorIndex + 1).replace(/[.,]/g, '');
            }
        }
        else {
            // Remove any character that is not a digit or minus sign
            parsedValue = parsedValue.replace(/[^\d-]/g, '');
            // Ensure only one minus sign at the start
            parsedValue = parsedValue.replace(/(?!^)-/g, '');
        }
        setValue(parsedValue);
        handlePassValue(parsedValue);
    };
    const handleBlur = (e) => {
        let newValue = value || '';
        if (props.decimals) {
            // Replace ',' with '.' for consistent decimal separator
            newValue = newValue.replace(',', '.');
            // Remove leading zeros before decimal point
            newValue = newValue.replace(/^(-?)0+(\d+)(\.\d*)?$/, (match, minus, num, decimals) => {
                return (minus || '') + num + (decimals || '');
            });
            // If the value is just '-', reset to empty string
            if (newValue === '-') {
                newValue = '';
            }
        }
        else {
            // Remove leading zeros
            newValue = newValue.replace(/^(-?)0+(\d+)$/, (match, minus, num) => {
                return (minus && num !== '0' ? '-' : '') + num;
            });
            // If the value is just '-', reset to empty string
            if (newValue === '-') {
                newValue = '';
            }
            // If empty, set to '0'
            newValue = newValue || '0';
        }
        setValue(newValue);
        props.onBlur?.(e);
    };
    return ((0, jsx_runtime_1.jsx)(Input_1.Input, { ...props, onChange: handleValueChange, onBlur: handleBlur, type: "text", value: value, schema: schema }));
};
exports.DigitInput = DigitInput;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Div_1 = require("./Div");
const SIZES = {
    sm: 720,
    md: 1088,
    lg: 1280,
};
const Container = ({ size = 'md', styles, ...restProps }) => {
    const maxWidth = SIZES[size];
    return ((0, jsx_runtime_1.jsx)(Div_1.Div, { ...restProps, styles: {
            boxSizing: 'border-box',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth,
            paddingLeft: 24,
            paddingRight: 24,
            position: 'relative',
            width: '100%',
            ...styles,
        } }));
};
exports.Container = Container;

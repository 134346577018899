import { ReactNode, useMemo } from 'react'

import {
  ApolloClient,
  ApolloProvider as ApolloApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

import { generateId } from '../utils/id'
import { useLocale } from '../ui'
import { clientConfig } from '../config/clientConfig'
import { isEnv } from '../utils/env'
import { Auth, onAuthStateChanged, User } from 'firebase/auth'
import { useFirebase } from './hooks/useFirebase'

const httpLink = createHttpLink({
  uri: clientConfig.GRAPHQL_URL,
  fetch,
})

const getCurrentUser = (auth: Auth): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe()
        resolve(user)
      },
      reject,
    )
  })
}

export const ApolloProvider = ({ children }: { children: ReactNode }) => {
  const locale = useLocale()
  const { getAuth } = useFirebase()

  const apolloClient = useMemo(() => {
    const authLink = setContext(async (_, context) => {
      const { headers, isAuthRequired } = context

      const newHeaders = {
        ...headers,
        ...(locale && {
          'x-locale': locale,
        }),
        'x-request-id': generateId(),
        'x-git-commit-sha': clientConfig.GIT_COMMIT_SHA,
      }

      if (isAuthRequired === false) {
        return {
          headers: newHeaders,
        }
      }

      // Force the authentication step to finish before running the query
      const currentUser = await getCurrentUser(getAuth())
      const idToken = currentUser ? await currentUser.getIdToken() : null

      return {
        headers: {
          ...newHeaders,
          ...(idToken && {
            authorization: `Bearer ${idToken}`,
          }),
        },
      }
    })

    return new ApolloClient({
      link: authLink.concat(httpLink),
      connectToDevTools: isEnv('development'),
      credentials: 'include',
      cache: new InMemoryCache(),
    })
  }, [locale])

  return (
    <ApolloApolloProvider client={apolloClient}>
      {children}
    </ApolloApolloProvider>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeStyles = void 0;
const mergeStyles = (styles) => {
    /**
     * Styled system uses `any` as the return type, so we will too.
     */
    const output = {};
    for (const style of styles) {
        for (const [property, value] of Object.entries(style)) {
            if (property.startsWith('@media')) {
                if (value instanceof Object) {
                    output[property] ??= {};
                    Object.assign(output[property], value);
                }
            }
            else {
                output[property] = style[property];
            }
        }
    }
    return output;
};
exports.mergeStyles = mergeStyles;

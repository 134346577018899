"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Content = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Biography_1 = require("./biography/Biography");
const ContactRequests_1 = require("./contact-requests/ContactRequests");
const Information_1 = require("./information/Information");
const Interests_1 = require("./interests/Interests");
const References_1 = require("./references/References");
const Vacancies_1 = require("./vacancies/Vacancies");
const elements_1 = require("../../elements");
const hooks_1 = require("../../hooks");
const Contract_1 = require("./contract/Contract");
const DesktopGrid = ({ biography, contract, references, interests, information, contactRequests, vacancies, children, }) => {
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3.5, styles: { flex: 1 }, children: [biography, information, contract, children, references, interests, contactRequests, vacancies] }));
};
const MobileGrid = ({ biography, contract, references, interests, information, contactRequests, vacancies, children, }) => {
    return ((0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 3.5, children: [biography, contract, children, references, interests, information, contactRequests, vacancies] }));
};
const Content = ({ actions, contactRequests, matchingVacancies, matchingVacanciesLoading, children, }) => {
    const breakpoint = (0, hooks_1.useBreakpoint)();
    const Component = breakpoint.gte.md ? DesktopGrid : MobileGrid;
    return ((0, jsx_runtime_1.jsx)(Component, { biography: (0, jsx_runtime_1.jsx)(Biography_1.Biography, { onAddAuthorizationClick: actions.onAddAuthorizationClick, onAddFileUploadClick: actions.onAddFileUploadClick, onAddReferenceClick: actions.onAddReferenceClick, onEditFileUploadClick: actions.onEditFileUploadClick, onOpenFileUploadClick: actions.onOpenFileUploadClick, onSendMessageClick: actions.onSendMessageClick }), contract: (0, jsx_runtime_1.jsx)(Contract_1.Contract, {}), references: (0, jsx_runtime_1.jsx)(References_1.References, { onAddReferenceClick: actions.onAddReferenceClick, onDeleteReferenceClick: actions.onDeleteReferenceClick, onReferenceClick: actions.onReferenceClick }), interests: (0, jsx_runtime_1.jsx)(Interests_1.Interests, { onIsInterestedInContactChange: actions.onIsInterestedInContactChange, onIsInterestedInMatchingVacanciesChange: actions.onIsInterestedInMatchingVacanciesChange }), information: (0, jsx_runtime_1.jsx)(Information_1.Information, {}), contactRequests: contactRequests ? ((0, jsx_runtime_1.jsx)(ContactRequests_1.ContactRequests, { contactRequests: contactRequests, onContactRequestClick: actions.onContactRequestClick, onOpenContactRequestsClick: actions.onOpenContactRequestsClick })) : null, vacancies: (0, jsx_runtime_1.jsx)(Vacancies_1.Vacancies, { compact: true, matchingVacancies: matchingVacancies, matchingVacanciesLoading: matchingVacanciesLoading, onOpenVacanciesClick: actions.onOpenVacanciesClick, onVacancyClick: actions.onVacancyClick }), children: children }));
};
exports.Content = Content;

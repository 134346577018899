import { track, useAnalytics } from '@bemlo/tracking'
import {
  withDialog,
  Dialog,
  Card,
  Heading,
  Paragraph,
  Text,
  Button,
  Color,
  Checkbox,
  Row,
  Column,
} from '../ui'
import { useEffect, useState } from 'react'
import { marketConfig } from '../config/market-configs'
import { useTranslation } from '../translations'
import {
  useCookiePreferenceContext,
  CookiePreference,
} from './context/CookiePreferenceContext'
import { Link } from './WrappedLink'

const COOKIE_BANNER_DELAY_MS = 300

type CookieDialogProps = {
  accept: (cookiePreference: CookiePreference) => void
}

const CookieDialog = withDialog<CookieDialogProps>(
  ({ accept, initialFocus }) => {
    const t = useTranslation('cookieConsent')
    const [page, setPage] = useState<'overview' | 'custom'>('overview')
    const [allowAnalytics, setAllowAnalytics] = useState(false)

    return (
      <Card as={Dialog.Panel} maxWidth={480} width="100%">
        <Heading level={2} size={1} pt={4} px={4} mb={0} color={Color.BLUE}>
          {t.we_value_your_privacy}
        </Heading>

        {page === 'overview' ? (
          <Column p={4}>
            <Paragraph>{t.cookies_description}</Paragraph>

            <Text textDecoration="underline" color={Color.BLUE}>
              {/* TODO: update to /cookies when we have translated that page for other markets */}
              <Link openNewTab to="https://www.bemlo.se/cookies/">
                {t.how_we_use_cookies}
              </Link>
            </Text>

            <Column gridGap={2} mt={4}>
              <Button
                design="primary"
                onClick={() => accept('ALL_COOKIES')}
                ref={initialFocus}
              >
                {t.accept_all_cookies}
              </Button>

              <Button design="inverted" onClick={() => setPage('custom')}>
                {t.custom_selection}
              </Button>
            </Column>
          </Column>
        ) : (
          <Column p={4}>
            <Paragraph>{t.choose_cookies}</Paragraph>

            <label>
              <Row>
                <Checkbox disabled={true} checked={true} />
                <Text ml={2} bold>
                  {t.necessary_cookies}
                </Text>
              </Row>

              <Paragraph mt={2}>{t.necessary_cookies_description}</Paragraph>
            </label>

            <label>
              <Row>
                <Checkbox
                  checked={allowAnalytics}
                  onChange={setAllowAnalytics}
                />
                <Text ml={2} bold>
                  {t.analytics_cookies}
                </Text>
              </Row>

              <Paragraph mt={2}>{t.analytics_cookies_description}</Paragraph>
            </label>

            <Column gridGap={2}>
              <Button
                design="inverted"
                onClick={() =>
                  accept(allowAnalytics ? 'ALL_COOKIES' : 'NECESSARY_COOKIES')
                }
                ref={initialFocus}
              >
                {t.accept_selected}
              </Button>

              <Button design="primary" onClick={() => accept('ALL_COOKIES')}>
                {t.accept_all}
              </Button>
            </Column>
          </Column>
        )}
      </Card>
    )
  },
)

export const CookieConsent = () => {
  const [cookiePreference, setCookiePreference] = useCookiePreferenceContext()
  const [isOpen, setIsOpen] = useState(false)

  const handleAccept = (cookiePreference: CookiePreference) => {
    setCookiePreference(cookiePreference)

    if (cookiePreference === 'ALL_COOKIES') {
      track('All Cookies Accepted')
    } else {
      track('Necessary Cookies Accepted')
    }

    setIsOpen(false)
  }

  useAnalytics({
    enabled: cookiePreference === 'ALL_COOKIES',
    writeKey: marketConfig.segmentProdKey,
  })

  useEffect(() => {
    if (!cookiePreference) {
      setTimeout(() => {
        setIsOpen(true)
      }, COOKIE_BANNER_DELAY_MS)
    }
  }, [])

  return (
    <CookieDialog
      isOpen={isOpen}
      onClose={() => undefined}
      accept={handleAccept}
    />
  )
}

import { ReactNode, useEffect, useState } from 'react'

type NoSSRProps = {
  children?: ReactNode
}

export const NoSSR = ({ children }: NoSSRProps) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    setRender(true)
  }, [])

  return <>{render ? children : null}</>
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftBookingCancellationReason = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftBookingCancellationReason = {
    [enums_1.ShiftBookingCancellationReason.SICKNESS]: 'Sykdom',
    [enums_1.ShiftBookingCancellationReason.CARE_OF_CHILD]: 'Omsorg for barn',
    [enums_1.ShiftBookingCancellationReason.PERSONAL_REASONS]: 'Personlige grunner',
    [enums_1.ShiftBookingCancellationReason.NO_LONGER_NEEDED]: 'Ikke lenger nødvendig',
};

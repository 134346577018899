"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftBookingCancellationReason = void 0;
var ShiftBookingCancellationReason;
(function (ShiftBookingCancellationReason) {
    ShiftBookingCancellationReason["SICKNESS"] = "SICKNESS";
    ShiftBookingCancellationReason["CARE_OF_CHILD"] = "CARE_OF_CHILD";
    ShiftBookingCancellationReason["PERSONAL_REASONS"] = "PERSONAL_REASONS";
    ShiftBookingCancellationReason["NO_LONGER_NEEDED"] = "NO_LONGER_NEEDED";
})(ShiftBookingCancellationReason || (exports.ShiftBookingCancellationReason = ShiftBookingCancellationReason = {}));

import styled from '@emotion/styled'

import { Color, theme } from '../imports'

export type TableHeadCellProps = {
  textAlign?: 'left' | 'center' | 'right'
  width?: number | string
}

export const TableHeadCell = styled('th', {
  shouldForwardProp: (prop) => prop !== 'width',
})<TableHeadCellProps>((props) => ({
  color: Color.DARK_GREY,
  fontSize: theme.font.size[6.5],
  padding: props.textAlign === 'center' ? '12px 0' : '12px 20px',
  textAlign: props.textAlign ?? 'left',
  userSelect: 'none',
  width: props.width,
}))

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHIFT_HOURS_STYLES = void 0;
const constants_1 = require("../constants");
exports.SHIFT_HOURS_STYLES = {
    DAY: {
        icon: 'DayIcon',
        color: constants_1.Color.MEDIUM_BLUE,
    },
    EVENING: {
        icon: 'EveningIcon',
        color: constants_1.Color.MEDIUM_RED,
    },
    NIGHT: {
        icon: 'NightIcon',
        color: constants_1.Color.VIOLET,
    },
    LONG: {
        icon: 'DayEveningIcon',
        color: constants_1.Color.PINK,
    },
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMinuteInInterval = exports.formatTime = void 0;
const enums_1 = require("@bemlo/enums");
const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};
exports.formatTime = formatTime;
// Map day indices so that Monday is 0
const DAYS_OF_WEEK = {
    0: enums_1.CompensationModelDay.MONDAY,
    1: enums_1.CompensationModelDay.TUESDAY,
    2: enums_1.CompensationModelDay.WEDNESDAY,
    3: enums_1.CompensationModelDay.THURSDAY,
    4: enums_1.CompensationModelDay.FRIDAY,
    5: enums_1.CompensationModelDay.SATURDAY,
    6: enums_1.CompensationModelDay.SUNDAY,
};
const isIntervalValidForDate = (interval, date, { isHoliday, isSmallHoliday, isDayBeforeHoliday }) => {
    // Adjust day index: (day() returns 0 for Sunday; shift so Monday is 0)
    const dayIndex = (date.day() + 6) % 7;
    const dayName = DAYS_OF_WEEK[dayIndex];
    const isValidForDay = interval.validDays.some((validDay) => {
        switch (validDay) {
            case enums_1.CompensationModelDay.HOLIDAY:
                return isHoliday(date);
            case enums_1.CompensationModelDay.SMALL_HOLIDAY:
                return isSmallHoliday(date);
            case enums_1.CompensationModelDay.DAY_BEFORE_HOLIDAY:
                return isDayBeforeHoliday(date);
            default:
                return validDay === dayName;
        }
    });
    return isValidForDay;
};
/**
 * Checks if the given currentTime falls within the interval.
 * For intervals spanning midnight, if the current time is in the early hours,
 * we use the previous day as the effective date.
 */
const isMinuteInInterval = (currentTime, interval, holidays) => {
    if (!interval.startTime || !interval.endTime)
        return false;
    const [intStartHour, intStartMinute] = interval.startTime
        .split(':')
        .map(Number);
    const [intEndHour, intEndMinute] = interval.endTime === '24:00'
        ? [0, 0]
        : interval.endTime.split(':').map(Number);
    const spansMidnight = interval.endTime === '24:00' ||
        intEndHour < intStartHour ||
        (intEndHour === intStartHour && intEndMinute <= intStartMinute);
    // Determine effective date: if the interval spans midnight and we're in the early hours,
    // use the previous day for the validity check.
    let effectiveDate = currentTime;
    if (spansMidnight && currentTime.hour() < intEndHour) {
        effectiveDate = currentTime.subtract(1, 'day');
    }
    if (!isIntervalValidForDate(interval, effectiveDate, holidays)) {
        return false;
    }
    // Build boundaries using the effective date.
    const intervalStart = effectiveDate
        .set('hour', intStartHour)
        .set('minute', intStartMinute)
        .set('second', 0)
        .set('millisecond', 0);
    let intervalEnd = effectiveDate
        .set('hour', intEndHour)
        .set('minute', intEndMinute)
        .set('second', 0)
        .set('millisecond', 0);
    if (spansMidnight) {
        intervalEnd = intervalEnd.add(1, 'day');
    }
    const isSameOrAfter = currentTime.isSame(intervalStart) || currentTime.isAfter(intervalStart);
    return isSameOrAfter && currentTime.isBefore(intervalEnd);
};
exports.isMinuteInInterval = isMinuteInInterval;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelevantHolidaysInPeriod = void 0;
const holidays_1 = require("@bemlo/holidays");
const SWEDISH_HOLIDAYS = new Set([
    'Nyårsdagen',
    'Trettondedag jul',
    'Långfredagen',
    'Påskafton',
    'Påskdagen',
    'Annandag påsk',
    'Midsommarafton',
    'Midsommardagen',
    'Julafton',
    'Juldagen',
    'Annandag jul',
    'Nyårsafton',
]);
const SMALL_SWEDISH_HOLIDAYS = new Set([
    'Sveriges nationaldag',
    'Första Maj',
    'Kristi himmelfärds dag',
    'Alla Helgons dag',
]);
const getRelevantHolidaysInPeriod = (country, startDate, endDate) => {
    const holidays = (0, holidays_1.getHolidays)(country, startDate, endDate);
    const holidayMap = {
        holidays: holidays
            .filter((holiday) => SWEDISH_HOLIDAYS.has(holiday.name))
            .map((holiday) => holiday.date),
        smallHolidays: holidays
            .filter((holiday) => SMALL_SWEDISH_HOLIDAYS.has(holiday.name))
            .map((holiday) => holiday.date),
    };
    return {
        isHoliday: (date) => !!holidayMap.holidays.find((holiday) => holiday.isSame(date, 'day')),
        isSmallHoliday: (date) => !!holidayMap.smallHolidays.find((holiday) => holiday.isSame(date, 'day')),
        isDayBeforeHoliday: (date) => !!holidayMap.holidays.find((holiday) => holiday.subtract(1, 'day').isSame(date, 'day')),
    };
};
exports.getRelevantHolidaysInPeriod = getRelevantHolidaysInPeriod;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profession = void 0;
const enums_1 = require("@bemlo/enums");
exports.profession = {
    [enums_1.Profession.ASSISTANT_NURSE]: {
        short: 'USK',
        title: {
            '*': 'Undersköterskor',
            '1': 'Undersköterska',
        },
    },
    [enums_1.Profession.AUDIOLOGIST]: {
        short: 'AUD',
        title: {
            '*': 'Audionomer',
            '1': 'Audionom',
        },
    },
    [enums_1.Profession.CARER]: {
        short: 'VBI',
        title: {
            '*': 'Vårdbiträden',
            '1': 'Vårdbiträde',
        },
    },
    [enums_1.Profession.DOCTOR]: {
        short: 'LÄK',
        title: {
            '*': 'Läkare',
            '1': 'Läkare',
        },
    },
    [enums_1.Profession.MEDICAL_LABORATORY_TECHNICIAN]: {
        short: 'BIO',
        title: {
            '*': 'Biomedicinska analytiker',
            '1': 'Biomedicinsk analytiker',
        },
    },
    [enums_1.Profession.NURSE]: {
        short: 'SSK',
        title: {
            '*': 'Sjuksköterskor',
            '1': 'Sjuksköterska',
        },
    },
    [enums_1.Profession.OCCUPATIONAL_THERAPIST]: {
        short: 'ARB',
        title: {
            '*': 'Arbetsterapeuter',
            '1': 'Arbetsterapeut',
        },
    },
    [enums_1.Profession.PHYSIOTHERAPIST]: {
        short: 'FYS',
        title: {
            '*': 'Fysioterapeuter',
            '1': 'Fysioterapeut',
        },
    },
    [enums_1.Profession.PSYCHOLOGIST]: {
        short: 'PSYK',
        title: {
            '*': 'Psykologer',
            '1': 'Psykolog',
        },
    },
    [enums_1.Profession.PSYCHOTHERAPIST]: {
        short: 'PSTH',
        title: {
            '*': 'Psykoterapeuter',
            '1': 'Psykoterapeut',
        },
    },
    [enums_1.Profession.SOCIALSEKRETERARE]: {
        short: 'SOC',
        title: {
            '*': 'Socialsekreterare',
            '1': 'Socialsekreterare',
        },
    },
    [enums_1.Profession.SOCIONOM]: {
        short: 'SOCM',
        title: {
            '*': 'Socionomer',
            '1': 'Socionom',
        },
    },
    [enums_1.Profession.SUPPORT_WORKER]: {
        short: 'BPED',
        title: {
            '*': 'Behandlingspedagoger',
            '1': 'Behandlingspedagog',
        },
    },
};
// TODO: replace with TypeScript satisfies when TypeScript is upgraded
const _satisfies = exports.profession;

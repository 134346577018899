"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateShiftCompensations = exports.calculateShiftCompensation = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
const enums_1 = require("@bemlo/enums");
const calculate_on_call_compensation_1 = require("./calculate-on-call-compensation");
const calculate_ordinary_compensation_1 = require("./calculate-ordinary-compensation");
const holidays_1 = require("./holidays");
const on_call_disturbances_1 = require("../utils/on-call-disturbances");
const handleCalculateShiftTime = (basePay, shift, timeReport, model, holidays) => {
    const onCallTime = shift.onCallTime;
    if (!onCallTime) {
        return (0, calculate_ordinary_compensation_1.calculateOrdinaryCompensation)({
            startDateTime: timeReport.startDateTime,
            endDateTime: timeReport.endDateTime,
            breakStartDateTime: timeReport.startDateTime,
            breakDuration: timeReport.breakTime,
            intervals: model.intervals,
            holidays,
            basePay,
        });
    }
    const isOnlyOnCall = onCallTime.startTime === shift.startTime &&
        onCallTime.endTime === shift.endTime;
    const onCallTimeResults = (0, calculate_on_call_compensation_1.calculateOnCallCompensation)({
        isResident: onCallTime.type === enums_1.OnCallType.RESIDENT,
        startDateTime: onCallTime.startDateTime,
        endDateTime: onCallTime.endDateTime,
        disturbedBlocks: (0, on_call_disturbances_1.getDisturbedBlocks)(timeReport.onCallDisturbances?.map((disturbance) => ({
            startDateTime: (0, date_1.dayjsEn)(disturbance.startDateTime).unix(),
            endDateTime: (0, date_1.dayjsEn)(disturbance.endDateTime).unix(),
        })) ?? [], 30),
        intervals: model.intervals,
        holidays,
        basePay,
    });
    if (isOnlyOnCall) {
        return onCallTimeResults;
    }
    let totalMinutes = onCallTimeResults.totalMinutes;
    let totalCompensationAmount = onCallTimeResults.totalCompensationAmount;
    let standardMinutes = onCallTimeResults.standardMinutes;
    let standardCompensationAmount = onCallTimeResults.standardCompensationAmount;
    let intervalResults = [
        ...onCallTimeResults.intervalResults,
    ];
    // If the shift starts with ordinary time
    if (onCallTime.startTime !== shift.startTime) {
        const result = (0, calculate_ordinary_compensation_1.calculateOrdinaryCompensation)({
            startDateTime: timeReport.startDateTime,
            endDateTime: onCallTime.startDateTime,
            breakStartDateTime: timeReport.startDateTime,
            breakDuration: 0, // Is this correct? we'll subtract it from the total amount... feels unsafe
            intervals: model.intervals,
            holidays,
            basePay,
        });
        totalMinutes += result.totalMinutes;
        totalCompensationAmount += result.totalCompensationAmount;
        standardMinutes += result.standardMinutes;
        standardCompensationAmount += result.standardCompensationAmount;
        intervalResults = intervalResults.concat(result.intervalResults);
    }
    // If the shift does not end with on call
    if (onCallTime.endTime !== shift.endTime) {
        const result = (0, calculate_ordinary_compensation_1.calculateOrdinaryCompensation)({
            startDateTime: onCallTime.endDateTime,
            endDateTime: timeReport.endDateTime,
            breakStartDateTime: onCallTime.endDateTime,
            breakDuration: 0, // Is this correct? we'll subtract it from the total amount... feels unsafe
            intervals: model.intervals,
            holidays,
            basePay,
        });
        totalMinutes += result.totalMinutes;
        totalCompensationAmount += result.totalCompensationAmount;
        standardMinutes += result.standardMinutes;
        standardCompensationAmount += result.standardCompensationAmount;
        intervalResults = intervalResults.concat(result.intervalResults);
    }
    return {
        date: timeReport.startDateTime,
        totalMinutes: totalMinutes - timeReport.breakTime,
        totalCompensationAmount,
        // This does not work since it might be 0, then we'd have to subtract it from the total amount
        standardMinutes: standardMinutes - timeReport.breakTime,
        standardCompensationAmount,
        intervalResults,
    };
};
const calculateShiftCompensation = (basePay, shift, timeReport, model) => {
    return handleCalculateShiftTime(basePay, shift, timeReport, model, (0, holidays_1.getRelevantHolidaysInPeriod)(model.country, timeReport.startDateTime, timeReport.endDateTime));
};
exports.calculateShiftCompensation = calculateShiftCompensation;
const calculateShiftCompensations = (basePay, data, model) => {
    const firstDate = (0, lodash_1.minBy)(data, ({ timeReport }) => timeReport.startDateTime)
        ?.timeReport.startDateTime;
    const lastDate = (0, lodash_1.maxBy)(data, ({ timeReport }) => timeReport.endDateTime)
        ?.timeReport?.endDateTime;
    if (!firstDate || !lastDate) {
        throw new Error('No period found');
    }
    const holidays = (0, holidays_1.getRelevantHolidaysInPeriod)(model.country, firstDate, lastDate);
    return data.map(({ shift, timeReport }) => handleCalculateShiftTime(basePay, shift, timeReport, model, holidays));
};
exports.calculateShiftCompensations = calculateShiftCompensations;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftStatusBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const enums_1 = require("@bemlo/enums");
const Badge_1 = require("./Badge");
const translate_1 = require("../translate");
const STATUS_COLOR_MAP = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: 'danger',
    [enums_1.DynamicShiftStatus.APPLIED]: 'yellow',
    [enums_1.DynamicShiftStatus.SUGGESTED]: 'yellow',
    [enums_1.DynamicShiftStatus.ACCEPTED]: 'lighter-green',
    [enums_1.DynamicShiftStatus.AVAILABLE]: 'dark-blue',
    [enums_1.DynamicShiftStatus.BOOKED]: 'darker-green',
    [enums_1.DynamicShiftStatus.CANCELED]: 'grey',
    [enums_1.DynamicShiftStatus.OCCUPIED]: 'grey',
    [enums_1.DynamicShiftStatus.REJECTED]: 'red',
    [enums_1.DynamicShiftStatus.WITHDRAWN]: 'golden-rod',
};
const ShiftStatusBadge = ({ status }) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsx)(Badge_1.Badge, { color: STATUS_COLOR_MAP[status], size: "sm", children: t.$dynamicShiftStatus(status) }));
};
exports.ShiftStatusBadge = ShiftStatusBadge;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompensationModelDay = void 0;
var CompensationModelDay;
(function (CompensationModelDay) {
    CompensationModelDay["MONDAY"] = "MONDAY";
    CompensationModelDay["TUESDAY"] = "TUESDAY";
    CompensationModelDay["WEDNESDAY"] = "WEDNESDAY";
    CompensationModelDay["THURSDAY"] = "THURSDAY";
    CompensationModelDay["FRIDAY"] = "FRIDAY";
    CompensationModelDay["SATURDAY"] = "SATURDAY";
    CompensationModelDay["SUNDAY"] = "SUNDAY";
    CompensationModelDay["DAY_BEFORE_HOLIDAY"] = "DAY_BEFORE_HOLIDAY";
    CompensationModelDay["SMALL_HOLIDAY"] = "SMALL_HOLIDAY";
    CompensationModelDay["HOLIDAY"] = "HOLIDAY";
})(CompensationModelDay || (exports.CompensationModelDay = CompensationModelDay = {}));

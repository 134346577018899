"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISTURBANCE_BLOCK_MINUTES = exports.getDisturbanceDurationMinutes = exports.calculateDisturbedTime = exports.getDisturbedBlocks = void 0;
const lodash_1 = require("lodash");
const date_1 = require("@bemlo/date");
/**
 * Returns an array of disturbed blocks.
 * Each block represents a full minWindow (e.g., 30 minutes) starting at a specific time.
 *
 * Disturbances are first merged into clusters (using the same logic as calculateDisturbedTime)
 * and then each cluster is split into discrete blocks of duration `minWindow`.
 *
 * @param disturbances Array of OnCallDisturbance
 * @param minWindow Minimum window in minutes (e.g., 30)
 * @returns Array of DisturbanceBlock objects
 */
const getDisturbedBlocks = (disturbances, minWindow) => {
    if (disturbances.length === 0) {
        return [];
    }
    // Convert minWindow minutes to seconds.
    const minWindowSec = minWindow * 60;
    // Sort disturbances by startDateTime ascending.
    const sorted = [...disturbances].sort((a, b) => a.startDateTime - b.startDateTime);
    // Cluster disturbances using similar logic as calculateDisturbedTime.
    // Each cluster is defined by a start and end timestamp.
    const clusters = [];
    let currentClusterStart = sorted[0].startDateTime;
    let currentClusterEnd = sorted[0].endDateTime;
    for (let i = 1; i < sorted.length; i++) {
        const d = sorted[i];
        const currentDuration = currentClusterEnd - currentClusterStart;
        const windows = Math.ceil(currentDuration / minWindowSec);
        // Calculate the boundary for the current cluster.
        const currentBoundary = currentClusterStart + windows * minWindowSec;
        // If the next disturbance starts before the boundary, merge it.
        if (d.startDateTime < currentBoundary) {
            currentClusterEnd = Math.max(currentClusterEnd, d.endDateTime);
        }
        else {
            clusters.push({ start: currentClusterStart, end: currentClusterEnd });
            currentClusterStart = d.startDateTime;
            currentClusterEnd = d.endDateTime;
        }
    }
    // Push the final cluster.
    clusters.push({ start: currentClusterStart, end: currentClusterEnd });
    // For each cluster, create one block per billing window.
    const blocks = [];
    clusters.forEach((cluster) => {
        const duration = cluster.end - cluster.start;
        const windows = Math.ceil(duration / minWindowSec);
        for (let i = 0; i < windows; i++) {
            const blockStart = cluster.start + i * minWindowSec;
            blocks.push({
                // Convert Unix timestamp (seconds) to a Dayjs object.
                startDateTime: date_1.dayjsEn.unix(blockStart),
                length: minWindow,
            });
        }
    });
    return blocks;
};
exports.getDisturbedBlocks = getDisturbedBlocks;
/**
 * Returns the duration of a single disturbance in minutes.
 */
const getDisturbanceDurationMinutes = (disturbance) => {
    return (disturbance.endDateTime - disturbance.startDateTime) / 60;
};
exports.getDisturbanceDurationMinutes = getDisturbanceDurationMinutes;
/**
 * Calculates the total disturbed time in minutes given an array of disturbances and a minimum window.
 * Each continuous group of disturbances is charged in full windows of `minWindow` minutes.
 *
 * The disturbances are first sorted by start time. They are then merged into clusters if a disturbance
 * starts before the current cluster’s billing window (starting at the cluster’s first disturbance time)
 * has expired. Once a disturbance falls exactly at or after the boundary, the current cluster is finalized,
 * and a new cluster is started.
 *
 * @param disturbances Array of OnCallDisturbance
 * @param minWindow Minimum window in minutes (e.g., 30)
 * @returns Total disturbed time in minutes
 */
const calculateDisturbedTime = (disturbances, minWindow) => {
    if (disturbances.length === 0) {
        return 0;
    }
    const blocks = getDisturbedBlocks(disturbances, minWindow);
    return (0, lodash_1.sumBy)(blocks, (block) => block.length);
};
exports.calculateDisturbedTime = calculateDisturbedTime;
const DISTURBANCE_BLOCK_MINUTES = 30;
exports.DISTURBANCE_BLOCK_MINUTES = DISTURBANCE_BLOCK_MINUTES;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HEADER_HEIGHT = exports.composableZIndex = exports.COLUMN_WIDTH = exports.DEFAULT_HEADER_COLUMN_WIDTH = void 0;
exports.DEFAULT_HEADER_COLUMN_WIDTH = 168;
exports.COLUMN_WIDTH = {
    week: 195,
    month: 44,
};
exports.composableZIndex = {
    head: 4,
    cellTitle: 2,
    headCell: 1,
    headCellTitle: 2,
    shiftError: 1,
    newShiftRow: 3,
};
exports.HEADER_HEIGHT = 44;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateCompensation = void 0;
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
/**
 * Rounds a number to three decimal places.
 */
const threeDecimalPlaces = (value) => Math.ceil(value * 1000) / 1000;
const calculateCompensation = ({ basePay, minutes, interval, }) => {
    const hours = (0, utils_1.minutesAsHours)(minutes);
    const baseCompensation = hours * basePay;
    if (!interval) {
        return {
            compensationAmount: threeDecimalPlaces(baseCompensation),
            pay: baseCompensation,
        };
    }
    const modifier = interval.modifier;
    const compensationValue = interval.compensationValue;
    const isAdditive = interval.isAdditive;
    switch (modifier) {
        case enums_1.CompensationModifier.ADDITION:
            if (isAdditive) {
                return {
                    compensationAmount: threeDecimalPlaces(compensationValue * hours),
                    pay: compensationValue,
                };
            }
            return {
                compensationAmount: threeDecimalPlaces(compensationValue + hours * compensationValue),
                pay: basePay + compensationValue,
            };
        case enums_1.CompensationModifier.FACTOR:
            if (isAdditive) {
                return {
                    compensationAmount: threeDecimalPlaces(baseCompensation * (compensationValue - 1)),
                    pay: (compensationValue - 1) * basePay,
                };
            }
            return {
                compensationAmount: threeDecimalPlaces(baseCompensation * compensationValue),
                pay: basePay * compensationValue,
            };
        case enums_1.CompensationModifier.NONE:
            return {
                compensationAmount: threeDecimalPlaces(baseCompensation),
                pay: basePay,
            };
        default:
            throw new Error(`Unknown compensation modifier: ${modifier}`);
    }
};
exports.calculateCompensation = calculateCompensation;

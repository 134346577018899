"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeTimeInput = exports.sanitizeTimeInput = void 0;
/**
 * Remove any characters except digits and colon.
 */
const sanitizeTimeInput = (input) => input.replace(/[^\d:]/g, '');
exports.sanitizeTimeInput = sanitizeTimeInput;
/**
 * Given a raw time input (which may or may not include a colon),
 * normalize it to a valid HH:mm string.
 *
 * Examples:
 *   "9"     -> "09:00"
 *   "730"   -> "07:30"
 *   "7:"    -> "07:00"
 *   "09:3" -> "09:03"
 *
 * For times exceeding the valid range (e.g. hours > 24 or 24 with minutes > 0)
 * we clamp the output to "24:00".
 */
const normalizeTimeInput = (input) => {
    const cleaned = (0, exports.sanitizeTimeInput)(input);
    if (!cleaned)
        return '';
    let hoursStr = '';
    let minutesStr = '';
    if (cleaned.includes(':')) {
        ;
        [hoursStr = '', minutesStr = ''] = cleaned.split(':');
    }
    else if (cleaned.length <= 2) {
        hoursStr = cleaned;
    }
    else {
        hoursStr = cleaned.slice(0, -2);
        minutesStr = cleaned.slice(-2);
    }
    const hours = parseInt(hoursStr, 10) || 0;
    const minutes = parseInt(minutesStr, 10) || 0;
    // Handle out of range values
    if (hours > 24 || (hours === 24 && minutes > 0)) {
        return '24:00';
    }
    const validHours = Math.max(0, hours);
    const validMinutes = Math.max(0, Math.min(59, minutes));
    const hh = validHours.toString().padStart(2, '0');
    const mm = validMinutes.toString().padStart(2, '0');
    return `${hh}:${mm}`;
};
exports.normalizeTimeInput = normalizeTimeInput;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenderInvoiceStatus = void 0;
var TenderInvoiceStatus;
(function (TenderInvoiceStatus) {
    TenderInvoiceStatus["CREATED"] = "CREATED";
    TenderInvoiceStatus["SENT"] = "SENT";
    TenderInvoiceStatus["PAID"] = "PAID";
    TenderInvoiceStatus["CLOSED"] = "CLOSED";
})(TenderInvoiceStatus || (exports.TenderInvoiceStatus = TenderInvoiceStatus = {}));

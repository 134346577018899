import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { TableCell } from './Cell'
import { TableRow } from './Row'
import { Color, theme, Checkbox, Div, Flex } from '../imports'

const Row = styled(TableRow)({
  borderBottom: theme.border.style.thinLight,
  borderTop: theme.border.style.thinLight,
  background: Color.BACKGROUND_GREY,
})

export const TableGroupHeader = ({
  label,
  children,
  checkbox,
  colSpan,
}: {
  label: ReactNode
  children?: React.ReactNode
  checkbox?: {
    checked: boolean
    onCheckedChanged: (checked: boolean) => void
  }
  colSpan: number
}) => {
  return (
    <>
      <Row>
        <TableCell colSpan={colSpan}>
          <Flex gap={3.5} items="center">
            {checkbox ? (
              <Checkbox
                checked={checkbox.checked}
                onChange={checkbox.onCheckedChanged}
                size="sm"
              />
            ) : (
              <Div styles={{ width: 16 }} />
            )}
            {label}
          </Flex>
        </TableCell>
      </Row>
      {children}
    </>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specialization = void 0;
const enums_1 = require("@bemlo/enums");
exports.specialization = {
    [enums_1.DoctorSpecialization.DOCTOR_AKUTSJUKVARD]: {
        education: 'Akutt- og mottaksmedisin',
        title: {
            '*': 'Spesialister i akutt- og mottaksmedisin',
            '1': 'Spesialist i akutt- og mottaksmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ALLMANMEDICIN]: {
        education: 'Allmennmedisin',
        title: {
            '*': 'Spesialister i allmennmedisin',
            '1': 'Spesialist i allmennmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ANESTESIOCHINTENSIVVARD]: {
        education: 'Anestosiologi',
        title: {
            '*': 'Spesialister i anestosiologi',
            '1': 'Spesialist i anestosiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSPSYKIATRI]: {
        education: 'Barne- og ungdmospsykiatri',
        title: {
            '*': 'Spesialister i barne- og ungdmospsykiatri',
            '1': 'Spesialist i barne- og ungdmospsykiatri',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSKIRURGI]: {
        education: 'Barnekirurgi',
        title: {
            '*': 'Spesialister i barnekirurgi',
            '1': 'Spesialist i barnekirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSMEDICIN]: {
        education: 'Barnesykdommer',
        title: {
            '*': 'Spesialister i barnesykdommer',
            '1': 'Spesialist i barnesykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HEMATOLOGI]: {
        education: 'Blodsykdommer',
        title: {
            '*': 'Spesialister i blodsykdommer',
            '1': 'Spesialist i blodsykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ENDOKRINOLOGIOCHDIABETOLOGI]: {
        education: 'Endokrinologi',
        title: {
            '*': 'Spesialister i endokrinologi',
            '1': 'Spesialist i endokrinologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_GASTROENTEROLOGI]: {
        education: 'Fordøyelsessykdommer',
        title: {
            '*': 'Spesialister i fordøyelsessykdommer',
            '1': 'Spesialist i fordøyelsessykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_REHABILITERINGSMEDICIN]: {
        education: 'Fysikalsk medisin og rehabilitering',
        title: {
            '*': 'Spesialister i fysikalsk medisin og rehabilitering',
            '1': 'Spesialist i fysikalsk medisin og rehabilitering',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_OBSTETRIKOCHGYNEKOLOGI]: {
        education: 'Fødselshjelp og kvinnesykdommer',
        title: {
            '*': 'Spesialister i fødselshjelp og kvinnesykdommer',
            '1': 'Spesialist i fødselshjelp og kvinnesykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KIRURGI]: {
        education: 'Generell kirurgi',
        title: {
            '*': 'Spesialister i generell kirurgi',
            '1': 'Spesialist i generell kirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_GERIATRIK]: {
        education: 'Geriatri',
        title: {
            '*': 'Spesialister i geriatri',
            '1': 'Spesialist i geriatri',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARDIOLOGI]: {
        education: 'Hjertesykdommer',
        title: {
            '*': 'Spesialister i hjertesykdommer',
            '1': 'Spesialist i hjertesykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_HUDOCHKONSSJUKDOMAR]: {
        education: 'Hud- og veneriske sykdommer',
        title: {
            '*': 'Spesialister i hud- og veneriske sykdommer',
            '1': 'Spesialist i hud- og veneriske sykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_IMMUNOLOGI]: {
        education: 'Immunologi og transfusjonsmedisin',
        title: {
            '*': 'Spesialister i immunologi og transfusjonsmedisin',
            '1': 'Spesialist i immunologi og transfusjonsmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_INTERNMEDICIN]: {
        education: 'Indremedisin',
        title: {
            '*': 'Spesialister i indremedisin',
            '1': 'Spesialist i indremedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_INFEKTIONSSJUKDOMAR]: {
        education: 'Infeksjonssykdommer',
        title: {
            '*': 'Spesialister i infeksjonssykdommer',
            '1': 'Spesialist i infeksjonssykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARLKIRURGI]: {
        education: 'Karkirurgi',
        title: {
            '*': 'Spesialister i karkirurgi',
            '1': 'Spesialist i karkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKFARMAKOLOGI]: {
        education: 'Klinisk farmakologi',
        title: {
            '*': 'Spesialister i klinisk farmakologi',
            '1': 'Spesialist i klinisk farmakologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_LUNGSJUKDOMAR]: {
        education: 'Lungesykdommer',
        title: {
            '*': 'Spesialister i lungesykdommer',
            '1': 'Spesialist i lungesykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKGENETIK]: {
        education: 'Medisinsk genetikk',
        title: {
            '*': 'Spesialister i medisinsk genetikk',
            '1': 'Spesialist i medisinsk genetikk',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKMIKROBIOLOGI]: {
        education: 'Medisinsk mikrobiologi',
        title: {
            '*': 'Spesialister i medisinsk mikrobiologi',
            '1': 'Spesialist i medisinsk mikrobiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROKIRURGI]: {
        education: 'Nevrokirurgi',
        title: {
            '*': 'Spesialister i nevrokirurgi',
            '1': 'Spesialist i nevrokirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROLOGI]: {
        education: 'Nevrologi',
        title: {
            '*': 'Spesialister i nevrologi',
            '1': 'Spesialist i nevrologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NJURMEDICIN]: {
        education: 'Nyresykdommer',
        title: {
            '*': 'Spesialister i nyresykdommer',
            '1': 'Spesialist i nyresykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ONKOLOGI]: {
        education: 'Onkologi',
        title: {
            '*': 'Spesialister i onkologi',
            '1': 'Spesialist i onkologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORTOPEDI]: {
        education: 'Ortopedisk kirurgi',
        title: {
            '*': 'Spesialister i ortopedisk kirurgi',
            '1': 'Spesialist i ortopedisk kirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PATOLOGI]: {
        education: 'Patologi',
        title: {
            '*': 'Spesialister i patologi',
            '1': 'Spesialist i patologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PLASTIKKIRURGI]: {
        education: 'Plastikkirurgi',
        title: {
            '*': 'Spesialister i plastikkirurgi',
            '1': 'Spesialist i plastikkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_PSYKIATRI]: {
        education: 'Psykiatri',
        title: {
            '*': 'Spesialister i psykiatri',
            '1': 'Spesialist i psykiatri',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_RADIOLOGI]: {
        education: 'Radiologi',
        title: {
            '*': 'Spesialister i radiologi',
            '1': 'Spesialist i radiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_REUMATOLOGI]: {
        education: 'Revmatologi',
        title: {
            '*': 'Spesialister i revmatologi',
            '1': 'Spesialist i revmatologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_THORAXKIRURGI]: {
        education: 'Thoraxkirurgi',
        title: {
            '*': 'Spesialister i thoraxkirurgi',
            '1': 'Spesialist i thoraxkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_UROLOGI]: {
        education: 'Urologi',
        title: {
            '*': 'Spesialister i urologi',
            '1': 'Spesialist i urologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_OGONSJUKDOMAR]: {
        education: 'Øyesykdommer',
        title: {
            '*': 'Spesialister i øyesykdommer',
            '1': 'Spesialist i øyesykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ARBETSOCHMILJOMEDICIN]: {
        education: 'Arbeidsmedisin',
        title: {
            '*': 'Spesialister i arbeidsmedisin',
            '1': 'Spesialist i arbeidsmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_BRYSTOGENDOKRINKIRURGI]: {
        education: 'Bryst- og endokrinkirurgi',
        title: {
            '*': 'Spesialister i bryst- og endokrinkirurgi',
            '1': 'Spesialist i bryst- og endokrinkirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKNEUROFYSIOLOGI]: {
        education: 'Klinisk nevrofysiologi',
        title: {
            '*': 'Spesialister i klinisk nevrofysiologi',
            '1': 'Spesialist i klinisk nevrofysiologi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_MAXILLOFACIALKIRURGI]: {
        education: 'Maxillofacial kirurgi',
        title: {
            '*': 'Spesialister i maxillofacial kirurgi',
            '1': 'Spesialist i maxillofacial kirurgi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKKEMI]: {
        education: 'Medisinsk biokjemi',
        title: {
            '*': 'Spesialister i medisinsk biokjemi',
            '1': 'Spesialist i medisinsk biokjemi',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NONE]: {
        education: 'Ingen',
        title: {
            '*': '',
            '1': '',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_NUKLEARMEDICIN]: {
        education: 'Nukleærmedisin',
        title: {
            '*': 'Spesialister i nukleærmedisin',
            '1': 'Spesialist i nukleærmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORONNASAHALSSJUKDOMAR]: {
        education: 'Øre-nese-halssykdommer',
        title: {
            '*': 'Spesialister i øre-nese-halssykdommer',
            '1': 'Spesialist i øre-nese-halssykdommer',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_RUSOGAVHENGIGHETSMEDISIN]: {
        education: 'Rus- og avhengighetsmedisin',
        title: {
            '*': 'Spesialister i rus- og avhengighetsmedisin',
            '1': 'Spesialist i rus- og avhengighetsmedisin',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_SPECIALIST_IN_TRAINING]: {
        education: 'LIS-lege',
        title: {
            '*': 'LIS-leger',
            '1': 'LIS-lege',
        },
    },
    [enums_1.DoctorSpecialization.DOCTOR_SOCIALMEDICIN]: {
        education: 'Samfunnsmedisin',
        title: {
            '*': 'Spesialister i samfunnsmedisin',
            '1': 'Spesialist i samfunnsmedisin',
        },
    },
    [enums_1.NurseSpecialization.NURSE_AKUTSJUKVARD]: {
        education: 'Akuttsykepleie',
        title: {
            '*': 'Spesialister i akuttsykepleie',
            '1': 'Spesialist i akuttsykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ANESTESTISJUKVARD]: {
        education: 'Anestesisykepleie',
        title: {
            '*': 'Spesialister i anestesisykepleie',
            '1': 'Spesialist i anestesisykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_BARNOCHUNGDOM]: {
        education: 'Barnesykepleie',
        title: {
            '*': 'Spesialister i barnesykepleie',
            '1': 'Spesialist i barnesykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_INTENSIVVARD]: {
        education: 'intensivsykepleier',
        title: {
            '*': 'Spesialister i intensivsykepleier',
            '1': 'Spesialist i intensivsykepleier',
        },
    },
    [enums_1.NurseSpecialization.NURSE_BARNMORSKA]: {
        education: 'Jordmor',
        title: {
            '*': 'Spesialister i jordmor',
            '1': 'Spesialist i jordmor',
        },
    },
    [enums_1.NurseSpecialization.NURSE_NONE]: {
        education: 'Ingen',
        title: {
            '*': '',
            '1': '',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DIABETES]: {
        education: 'Diabetessykepleie',
        title: {
            '*': 'Spesialister i diabetessykepleie',
            '1': 'Spesialist i diabetessykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_ONKOLOGISKVARD]: {
        education: 'Kreftsykepleie',
        title: {
            '*': 'Spesialister i kreftsykepleie',
            '1': 'Spesialist i kreftsykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_OPERATIONSSJUKVARD]: {
        education: 'Operasjonssykepleier',
        title: {
            '*': 'Spesialister i operasjonssykepleier',
            '1': 'Spesialist i operasjonssykepleier',
        },
    },
    [enums_1.NurseSpecialization.NURSE_GERIATRIK]: {
        education: 'Geriatri',
        title: {
            '*': 'Spesialister i geriatri',
            '1': 'Spesialist i geriatri',
        },
    },
    [enums_1.NurseSpecialization.NURSE_RONTGEN]: {
        education: 'Radiologi',
        title: {
            '*': 'Spesialister i radiologi',
            '1': 'Spesialist i radiologi',
        },
    },
    [enums_1.NurseSpecialization.NURSE_PSYKIATRISKVARD]: {
        education: 'Psykiatri',
        title: {
            '*': 'Spesialister i psykiatri',
            '1': 'Spesialist i psykiatri',
        },
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTKLINISKALLMENNSKYEPLEIE]: {
        education: 'Avansert klinisk allmennskyepleie',
        title: {
            '*': 'Spesialister i avansert klinisk allmennskyepleie ',
            '1': 'Spesialist i avansert klinisk allmennskyepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTKLINISKNYFODTSYKEPLEIE]: {
        education: 'Avansert klinisk nyfødtsykepleie',
        title: {
            '*': 'Spesialister i avansert klinisk nyfødtsykepleie',
            '1': 'Spesialist i avansert klinisk nyfødtsykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTSYKEPLEIE]: {
        education: 'Avansert sykepleie',
        title: {
            '*': 'Spesialister i avansert sykepleie',
            '1': 'Spesialist i avansert sykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_DERMATOLOGISKSYKEPLEIE]: {
        education: 'Dermatologisk sykepleie',
        title: {
            '*': 'Spesialister i dermatologisk sykepleie',
            '1': 'Spesialist i dermatologisk sykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_HELSESYKEPLEIE]: {
        education: 'Helsesykepleie',
        title: {
            '*': 'Spesialister i helsesykepleie',
            '1': 'Spesialist i helsesykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_KARDIOLOGISKSYKEPLEIE]: {
        education: 'Kardiologisk sykepleie',
        title: {
            '*': 'Spesialister i kardiologisk sykepleie',
            '1': 'Spesialist i kardiologisk sykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_KLINISKSYKEPLEIE]: {
        education: 'Klinisk sykepleie',
        title: {
            '*': 'Spesialister i klinisk sykepleie',
            '1': 'Spesialist i klinisk sykepleie',
        },
    },
    [enums_1.NurseSpecialization.NURSE_MASTERIHELSEOGSOSIALFAG]: {
        education: 'Master i helse- og sosialfag',
        title: {
            '*': 'Spesialister med master i helse- og sosialfag',
            '1': 'Spesialist med master i helse- og sosialfag',
        },
    },
    [enums_1.NurseSpecialization.NURSE_MASTERIHELSEVITENSKAP]: {
        education: 'Master i helsevitenskap',
        title: {
            '*': 'Spesialister i helsevitenskap',
            '1': 'Spesialist i helsevitenskap',
        },
    },
    [enums_1.NurseSpecialization.NURSE_PALLIATIVSYKEPLEIE]: {
        education: 'Palliativ sykepleie',
        title: {
            '*': 'Spesialister i palliativ sykepleie',
            '1': 'Spesialist i palliativ sykepleie',
        },
    },
};
// TODO: replace with TypeScript satisfies when TypeScript is upgraded
const _satisfies = exports.specialization;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profession = void 0;
var Profession;
(function (Profession) {
    Profession["ASSISTANT_NURSE"] = "ASSISTANT_NURSE";
    Profession["AUDIOLOGIST"] = "AUDIOLOGIST";
    Profession["CARER"] = "CARER";
    Profession["DOCTOR"] = "DOCTOR";
    Profession["MEDICAL_LABORATORY_TECHNICIAN"] = "MEDICAL_LABORATORY_TECHNICIAN";
    Profession["MEDICAL_SECRETARY"] = "MEDICAL_SECRETARY";
    Profession["NURSE"] = "NURSE";
    Profession["OCCUPATIONAL_THERAPIST"] = "OCCUPATIONAL_THERAPIST";
    Profession["PHYSIOTHERAPIST"] = "PHYSIOTHERAPIST";
    Profession["PSYCHOLOGIST"] = "PSYCHOLOGIST";
    Profession["PSYCHOTHERAPIST"] = "PSYCHOTHERAPIST";
    Profession["SENIOR_CARER"] = "SENIOR_CARER";
    Profession["SOCIAL_EDUCATOR"] = "SOCIAL_EDUCATOR";
    Profession["SOCIALSEKRETERARE"] = "SOCIALSEKRETERARE";
    Profession["SOCIONOM"] = "SOCIONOM";
    Profession["SUPPORT_WORKER"] = "SUPPORT_WORKER";
})(Profession || (exports.Profession = Profession = {}));

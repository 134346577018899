"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDatePicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const date_1 = require("@bemlo/date");
const picker_styles_1 = require("./picker-styles");
const PickerStepControls_1 = require("./PickerStepControls");
const translate_1 = require("../../translate");
const TimeDatePicker = ({ time, date, onTimeChange, onDateChange, disabled, step = 5, }) => {
    const t = (0, translate_1.useTranslations)();
    const parsedDate = t.$date(date);
    const [internalDate, setInternalDate] = (0, react_1.useState)(parsedDate.format(date_1.ISO_DATE_FORMAT));
    const parsedTime = (0, date_1.parseTime)(time);
    const updateInternalDate = () => {
        setInternalDate(parsedDate.format(date_1.ISO_DATE_FORMAT));
    };
    (0, react_1.useEffect)(updateInternalDate, [date]);
    const handleMinuteChange = (minute, step) => {
        const newTime = (0, date_1.normalizeTime)({
            hour: parsedTime.hour,
            minute,
        });
        onTimeChange((0, date_1.serializeTime)(newTime), step);
    };
    const handleMinusClick = () => {
        const minute = Math.ceil((parsedTime.minute - step) / step) * step;
        if (onDateChange && parsedTime.hour === 0 && minute < 0) {
            onDateChange(parsedDate.subtract(1, 'day').toDate());
        }
        handleMinuteChange(minute, -step);
    };
    const handlePlusClick = () => {
        const minute = Math.floor((parsedTime.minute + step) / step) * step;
        if (onDateChange && parsedTime.hour === 23 && minute >= 60) {
            onDateChange(parsedDate.add(1, 'day').toDate());
        }
        handleMinuteChange(minute, step);
    };
    const handleDateChange = (event) => {
        if (!onDateChange) {
            return;
        }
        setInternalDate(event.target.value);
        const newDate = t.$date(event.target.value);
        // Sanity check, so we ignore dates like 4444-01-01
        if (newDate.isAfter('2023-01-01') && newDate.isBefore('2100-01-01')) {
            onDateChange(newDate.toDate());
        }
    };
    const handleTimeChange = (event) => {
        onTimeChange(event.target.value);
    };
    return ((0, jsx_runtime_1.jsxs)(PickerStepControls_1.PickerStepButtons, { onMinusClick: handleMinusClick, onPlusClick: handlePlusClick, plusDisabled: disabled, minusDisabled: disabled, children: [(0, jsx_runtime_1.jsx)(picker_styles_1.FirstRowInput, { type: "time", value: time, onChange: handleTimeChange, disabled: disabled }), (0, jsx_runtime_1.jsx)(picker_styles_1.SecondRowInput, { disabled: !onDateChange || disabled, type: "date", value: internalDate, onChange: handleDateChange, onBlur: updateInternalDate })] }));
};
exports.TimeDatePicker = TimeDatePicker;

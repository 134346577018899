"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specializationsItems = specializationsItems;
const enums_1 = require("@bemlo/enums");
function specializationsItems(myEnum, defaults, overrides) {
    return Object.values(myEnum).map((specialization) => ({
        ...defaults,
        ...overrides[specialization],
        specialization,
    }));
}
const ALL_MARKETS = [enums_1.Market.DK, enums_1.Market.FI, enums_1.Market.GB, enums_1.Market.NO, enums_1.Market.SE];
const NURSE_SPECIALIZATION_ITEMS = specializationsItems(enums_1.NurseSpecialization, { profession: enums_1.Profession.NURSE }, {
    [enums_1.NurseSpecialization.NURSE_NONE]: {
        markets: ALL_MARKETS,
        isNone: true,
    },
    [enums_1.NurseSpecialization.NURSE_GENERIC_SPECIALIZATION]: {
        markets: ALL_MARKETS,
        isGeneric: true,
    },
    [enums_1.NurseSpecialization.NURSE_AKUTSJUKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_AMBULANSSJUKVARD]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_ANESTESTISJUKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_BARNMORSKA]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_BARNOCHUNGDOM]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_DISTRIKTSKOTERSKA]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_DIABETES]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_INTENSIVVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_KIRURGISKVARD]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_MEDICINSKVARD]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_ONKOLOGISKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_OPERATIONSSJUKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_PSYKIATRISKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_SILVIASJUKSKOTERSKA]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_SKOLSJUKVARD]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_VARDAVALDRE]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_GERIATRIK]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_RONTGEN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_ASTMA_KOL]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_DIALYS]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_ORTOPTIST]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_DEMENSVARD]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_OGONSJUKDOMAR]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_SJUKGYMNAST]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTKLINISKALLMENNSKYEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_DERMATOLOGISKSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_HELSESYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_MASTERIHELSEVITENSKAP]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_KARDIOLOGISKSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_AVANSERTKLINISKNYFODTSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_KLINISKSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_MASTERIHELSEOGSOSIALFAG]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.NurseSpecialization.NURSE_PALLIATIVSYKEPLEIE]: {
        markets: [enums_1.Market.NO],
    },
});
const DOCTOR_SPECIALIZATION_ITEMS = specializationsItems(enums_1.DoctorSpecialization, { profession: enums_1.Profession.DOCTOR }, {
    [enums_1.DoctorSpecialization.DOCTOR_NONE]: {
        markets: ALL_MARKETS,
        isNone: true,
    },
    [enums_1.DoctorSpecialization.DOCTOR_GENERIC_SPECIALIZATION]: {
        markets: ALL_MARKETS,
        isGeneric: true,
    },
    [enums_1.DoctorSpecialization.DOCTOR_AKUTSJUKVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ALLMANMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ANESTESIOCHINTENSIVVARD]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ARBETSOCHMILJOMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSKARDIOLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSKIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSNEUROLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSPSYKIATRI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ENDOKRINOLOGIOCHDIABETOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_GASTROENTEROLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_GERIATRIK]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_HANDKIRURGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_HEMATOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_HORSELOCHBALANSRUBBNINGAR]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_HUDOCHKONSSJUKDOMAR]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_INFEKTIONSSJUKDOMAR]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_INTERNMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARDIOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KARLKIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKFARMAKOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKFYSIOLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKGENETIK]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKKEMI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKMIKROBIOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_KLINISKNEUROFYSIOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_LUNGSJUKDOMAR]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEONATOLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROKIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEUROLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NEURORADIOLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NJURMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_NUKLEARMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_OBSTETRIKOCHGYNEKOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_OGONSJUKDOMAR]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ONKOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORONNASAHALSSJUKDOMAR]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ORTOPEDI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_PALLIATIVMEDICIN]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_PATOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_PLASTIKKIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_PSYKIATRI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_RADIOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_RATTSMEDICIN]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_REHABILITERINGSMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_REUMATOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ROSTOCHTALRUBBNINGAR]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_SOCIALMEDICIN]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_SPECIALIST_IN_TRAINING]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO, enums_1.Market.GB],
    },
    [enums_1.DoctorSpecialization.DOCTOR_THORAXKIRURGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_UROLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_ALLERGISJUKDOMAR]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BARNOCHUNGDOMSHEMATOLOGI]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_IMMUNOLOGI]: {
        markets: [enums_1.Market.SE, enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_UTLANDSKUTBILDNING]: {
        markets: [enums_1.Market.SE],
    },
    [enums_1.DoctorSpecialization.DOCTOR_BRYSTOGENDOKRINKIRURGI]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_MAXILLOFACIALKIRURGI]: {
        markets: [enums_1.Market.NO],
    },
    [enums_1.DoctorSpecialization.DOCTOR_RUSOGAVHENGIGHETSMEDISIN]: {
        markets: [enums_1.Market.NO],
    },
});
const SPECIALIZATION_ITEMS = [
    ...NURSE_SPECIALIZATION_ITEMS,
    ...DOCTOR_SPECIALIZATION_ITEMS,
];
exports.default = SPECIALIZATION_ITEMS;

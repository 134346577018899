"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftBookingCancellationReason = void 0;
const enums_1 = require("@bemlo/enums");
exports.shiftBookingCancellationReason = {
    [enums_1.ShiftBookingCancellationReason.SICKNESS]: 'Sickness',
    [enums_1.ShiftBookingCancellationReason.CARE_OF_CHILD]: 'Care of child',
    [enums_1.ShiftBookingCancellationReason.PERSONAL_REASONS]: 'Personal reasons',
    [enums_1.ShiftBookingCancellationReason.NO_LONGER_NEEDED]: 'No longer needed',
};

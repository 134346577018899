"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftHours = void 0;
var ShiftHours;
(function (ShiftHours) {
    ShiftHours["DAY"] = "DAY";
    ShiftHours["EVENING"] = "EVENING";
    ShiftHours["NIGHT"] = "NIGHT";
    ShiftHours["LONG"] = "LONG";
})(ShiftHours || (exports.ShiftHours = ShiftHours = {}));

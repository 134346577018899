"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamicShiftStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.dynamicShiftStatus = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: 'Ej vald',
    [enums_1.DynamicShiftStatus.APPLIED]: 'Ansökt',
    [enums_1.DynamicShiftStatus.AVAILABLE]: 'Tillgänglig',
    [enums_1.DynamicShiftStatus.BOOKED]: 'Bokad',
    [enums_1.DynamicShiftStatus.CANCELED]: 'Avbokad',
    [enums_1.DynamicShiftStatus.OCCUPIED]: 'Upptagen',
    [enums_1.DynamicShiftStatus.REJECTED]: 'Avböjd',
    [enums_1.DynamicShiftStatus.SUGGESTED]: 'Föreslagen',
    [enums_1.DynamicShiftStatus.ACCEPTED]: 'Accepterad',
    [enums_1.DynamicShiftStatus.WITHDRAWN]: 'Tillbakadragen',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompensationIntervalActivity = void 0;
var CompensationIntervalActivity;
(function (CompensationIntervalActivity) {
    CompensationIntervalActivity["ORDINARY"] = "ORDINARY";
    CompensationIntervalActivity["RESIDENT_ON_CALL"] = "RESIDENT_ON_CALL";
    CompensationIntervalActivity["ACTIVE_RESIDENT_ON_CALL"] = "ACTIVE_RESIDENT_ON_CALL";
    CompensationIntervalActivity["NON_RESIDENT_ON_CALL"] = "NON_RESIDENT_ON_CALL";
    CompensationIntervalActivity["ACTIVE_NON_RESIDENT_ON_CALL"] = "ACTIVE_NON_RESIDENT_ON_CALL";
})(CompensationIntervalActivity || (exports.CompensationIntervalActivity = CompensationIntervalActivity = {}));

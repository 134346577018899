import { Color, Container, Heading, Paragraph, Section, Text } from '../ui'
import { useTrackViewed } from '@bemlo/tracking'

import { useTranslation } from '../translations'

import { Link } from './WrappedLink'

type ErrorFallbackProps = {
  resetError?: () => void
}

const ErrorFallback = ({ resetError }: ErrorFallbackProps) => {
  const t = useTranslation('fallback')

  useTrackViewed('Error Fallback Viewed')

  return (
    <>
      <Section>
        <Container>
          <Heading level={1}>{t.an_error_occurred}</Heading>
          <Paragraph>🤖 {t.were_working_on_a_fix}</Paragraph>
          <Paragraph>
            <Link to="/" onClick={() => resetError?.()}>
              <Text bold color={Color.BLUE}>
                ‹ {t.to_the_start_page}
              </Text>
            </Link>
          </Paragraph>
        </Container>
      </Section>
    </>
  )
}

export default ErrorFallback

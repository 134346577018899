"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamicShiftStatus = void 0;
const enums_1 = require("@bemlo/enums");
exports.dynamicShiftStatus = {
    [enums_1.DynamicShiftStatus.APPLICATION_NOT_CHOSEN]: 'Ikke valgt',
    [enums_1.DynamicShiftStatus.APPLIED]: 'Søkt',
    [enums_1.DynamicShiftStatus.AVAILABLE]: 'Tilgjengelig',
    [enums_1.DynamicShiftStatus.BOOKED]: 'Bestilt',
    [enums_1.DynamicShiftStatus.CANCELED]: 'Kansellert',
    [enums_1.DynamicShiftStatus.OCCUPIED]: 'Opptatt',
    [enums_1.DynamicShiftStatus.REJECTED]: 'Avvist',
    [enums_1.DynamicShiftStatus.SUGGESTED]: 'Foreslått',
    [enums_1.DynamicShiftStatus.ACCEPTED]: 'Godkjent',
    [enums_1.DynamicShiftStatus.WITHDRAWN]: 'Tilbaketrukket',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCellTheme = void 0;
const react_1 = require("react");
const constants_1 = require("../../../../constants");
const translate_1 = require("../../../../translate");
const useCellTheme = () => {
    const t = (0, translate_1.useTranslations)();
    const themes = (0, react_1.useMemo)(() => ({
        DRAFT: {
            background: constants_1.Color.WHITE,
            color: constants_1.Color.DARK_GREY,
            subtitleOpacity: 0.65,
            label: t.shifts.draft(),
            border: `1px dashed ${constants_1.Color.MEDIUM_GREY}`,
            iconProps: {
                name: 'EmptyCircle',
                color: constants_1.Color.LIGHT_GREY,
            },
        },
        VACANT: {
            background: constants_1.Color.WHITE,
            color: constants_1.Color.DARK_GREY,
            subtitleOpacity: 0.65,
            label: t.shifts.vacant_one(),
            border: `1px solid ${constants_1.Color.BLUE}`,
            iconProps: {
                name: 'EmptyCircle',
                color: constants_1.Color.DARK_GREY,
            },
        },
        ARCHIVED: {
            background: constants_1.Color.TRANSPARENT,
            color: constants_1.Color.DARK_RED,
            subtitleOpacity: 0.85,
            label: t.shifts.archived(),
            border: `1px dashed ${constants_1.Color.DARK_RED}`,
            iconProps: {
                name: 'ArchiveIcon',
                color: constants_1.Color.MEDIUM_DARK_RED,
                design: 'solid',
            },
        },
        CANCELLED: {
            background: constants_1.Color.LIGHTEST_RED,
            color: constants_1.Color.DARK_RED,
            subtitleOpacity: 0.8,
            label: t.shifts.cancelled(),
            border: `1px solid ${constants_1.Color.LIGHTEST_RED}`,
            iconProps: {
                name: 'XCircleIcon',
                color: constants_1.Color.RED,
                design: 'solid',
            },
        },
        PRESENTED: {
            background: constants_1.Color.YELLOW,
            color: constants_1.Color.CHARCOAL,
            label: t.shifts.presented(),
            subtitleOpacity: 0.65,
            badgeColor: 'yellow',
            iconProps: {
                name: 'FlagIcon',
                color: constants_1.Color.MEDIUM_YELLOW,
                design: 'solid',
            },
        },
        APPLIED: {
            background: constants_1.Color.YELLOW,
            color: constants_1.Color.CHARCOAL,
            label: t.shifts.applied(),
            subtitleOpacity: 0.65,
            iconProps: {
                name: 'InProgress',
                color: constants_1.Color.MEDIUM_YELLOW,
            },
        },
        ACCEPTED: {
            background: constants_1.Color.LIGHTER_GREEN,
            color: constants_1.Color.DARKEST_GREEN,
            subtitleOpacity: 0.8,
            label: t.shifts.accepted(),
            badgeColor: 'dark-green',
            iconProps: {
                name: 'CheckIcon',
                color: constants_1.Color.GREEN,
            },
        },
        SUGGESTED: {
            background: constants_1.Color.YELLOW,
            color: constants_1.Color.DARK_YELLOW,
            label: t.shifts.suggested(),
            subtitleOpacity: 0.65,
            iconProps: {
                name: 'QuestionMarkCircleIcon',
                color: constants_1.Color.MEDIUM_YELLOW,
                design: 'solid',
            },
        },
        BOOKED: {
            background: constants_1.Color.GREEN,
            color: constants_1.Color.WHITE,
            label: t.shifts.booked(),
            subtitleOpacity: 0.8,
            badgeColor: 'lighter-green',
            iconProps: {
                name: 'CheckCircleIcon',
                color: constants_1.Color.GREEN,
                design: 'solid',
            },
        },
        FLEX: {
            background: constants_1.Color.YELLOW,
            color: constants_1.Color.CHARCOAL,
            label: t.shifts.flex_shift(),
            subtitleOpacity: 0.65,
            badgeColor: 'yellow',
            iconProps: {
                name: 'ClockIcon',
                color: constants_1.Color.MEDIUM_YELLOW,
                design: 'solid',
            },
        },
        SELECTED: {
            background: constants_1.Color.BLUE,
            color: constants_1.Color.WHITE,
            label: t.shifts.selected(),
            subtitleOpacity: 0.9,
            icon: 'CheckCircleIcon',
            badgeColor: 'blue',
            iconProps: {
                name: 'CheckCircleIcon',
                color: constants_1.Color.BLUE,
            },
        },
        REPLACING: {
            background: constants_1.Color.YELLOW,
            color: constants_1.Color.DARK_YELLOW,
            label: t.shifts.replacing(),
            subtitleOpacity: 0.65,
            icon: 'SwitchHorizontalIcon',
            iconProps: {
                name: 'SwitchHorizontalIcon',
                color: constants_1.Color.DARK_YELLOW,
            },
        },
        OCCUPIED: {
            background: constants_1.Color.MEDIUM_GREY,
            color: constants_1.Color.CHARCOAL,
            label: t.shifts.occupied(),
            subtitleOpacity: 0.65,
            iconProps: {
                name: 'LockClosedIcon',
                color: constants_1.Color.CHARCOAL,
            },
        },
        BLOCKED: {
            background: constants_1.Color.MEDIUM_GREY,
            color: constants_1.Color.CHARCOAL,
            label: t.shifts.blocked(),
            subtitleOpacity: 0.65,
            iconProps: {
                name: 'LockClosedIcon',
                color: constants_1.Color.CHARCOAL,
            },
        },
        REJECTED: {
            background: constants_1.Color.LIGHTEST_RED,
            color: constants_1.Color.DARK_RED,
            subtitleOpacity: 0.8,
            label: t.shifts.rejected(),
            border: `1px solid ${constants_1.Color.LIGHTEST_RED}`,
            badgeColor: 'dark-red',
            iconProps: {
                name: 'XIcon',
                color: constants_1.Color.DARK_RED,
            },
        },
        PRESET: {
            background: constants_1.Color.TRANSPARENT,
            hoverBackground: constants_1.Color.LIGHT_GREY,
            color: constants_1.Color.DARKER_GREY,
            border: constants_1.theme.border.style.thinDark,
            label: t.shifts.template(),
            subtitleOpacity: 0.65,
            iconProps: {
                name: 'TemplateIcon',
                color: constants_1.Color.BLUE,
            },
        },
        ACTIVE_PRESET: {
            background: constants_1.Color.LIGHTEST_BLUE,
            color: constants_1.Color.BLUE,
            border: `1px solid ${constants_1.Color.BLUE}`,
            label: t.shifts.selected(),
            subtitleOpacity: 0.65,
            icon: 'CheckIcon',
            badgeColor: 'dark-blue',
            iconProps: {
                name: 'TemplateIcon',
                color: constants_1.Color.BLUE,
            },
        },
    }), [t]);
    return themes;
};
exports.useCellTheme = useCellTheme;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateNHoursAndMinutesFactory = exports.translateNHoursFactory = void 0;
const locales_1 = require("../locales");
const translateNHoursFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('ui', locale);
    const roundHours = (hours) => {
        return (Math.round(hours / 0.25) * 0.25).toLocaleString(locale);
    };
    return (hours, short) => {
        return short
            ? `${roundHours(hours)}${t.hours_short()}`
            : `${roundHours(hours)} ${t.hours(hours)}`;
    };
};
exports.translateNHoursFactory = translateNHoursFactory;
const translateNHoursAndMinutesFactory = (locale) => {
    const t = (0, locales_1.getTranslation)('ui', locale);
    return (hours, short) => {
        const roundedHours = hours | 0;
        const minutes = Math.round((hours % 1) * 60);
        const hoursString = short
            ? `${roundedHours}${t.hours_short()}`
            : `${roundedHours} ${t.hours(Math.abs(roundedHours))}`;
        const minutesString = short
            ? `${minutes}${t.minutes_short()}`
            : `${minutes} ${t.minutes(Math.abs(minutes))}`;
        if (!roundedHours) {
            return minutesString;
        }
        if (!minutes) {
            return hoursString;
        }
        return `${hoursString} ${minutesString.replace(/^-/, '')}`;
    };
};
exports.translateNHoursAndMinutesFactory = translateNHoursAndMinutesFactory;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateOnCallCompensation = void 0;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
const common_1 = require("./common");
const utils_1 = require("./utils");
const calculateOnCallCompensation = ({ basePay, isResident, startDateTime, endDateTime, disturbedBlocks, intervals, holidays, }) => {
    // Decide which on-call interval types to use based on isResident.
    const activeType = isResident
        ? enums_1.CompensationIntervalActivity.ACTIVE_RESIDENT_ON_CALL
        : enums_1.CompensationIntervalActivity.ACTIVE_NON_RESIDENT_ON_CALL;
    const inactiveType = isResident
        ? enums_1.CompensationIntervalActivity.RESIDENT_ON_CALL
        : enums_1.CompensationIntervalActivity.NON_RESIDENT_ON_CALL;
    // Use only the on-call intervals (active and inactive)
    const inactiveOnCallIntervals = intervals.filter((i) => i.activity === inactiveType);
    const activeOnCallIntervals = intervals.filter((i) => i.activity === activeType);
    // Calculate shift duration (if the shift spans midnight, adjust end time)
    const start = startDateTime;
    let end = endDateTime;
    if (end.isBefore(start)) {
        end = end.add(1, 'day');
    }
    const totalMinutes = end.diff(start, 'minute');
    // Build a minute-by-minute assignment for the entire shift.
    // For each minute, we assign the first on-call interval that applies.
    const inactiveMinutes = new Array(totalMinutes).fill('');
    for (let m = 0; m < totalMinutes; m++) {
        const currentTime = start.add(m, 'minute');
        for (const interval of inactiveOnCallIntervals) {
            // Use our helper function that takes into account intervals spanning midnight.
            if ((0, common_1.isMinuteInInterval)(currentTime, interval, holidays)) {
                inactiveMinutes[m] = interval.id;
                break;
            }
        }
    }
    // Build a mapping: for each interval id, count the total minutes assigned.
    const totalAssignedMap = {};
    inactiveMinutes.forEach((id) => {
        if (id) {
            totalAssignedMap[id] = (totalAssignedMap[id] || 0) + 1;
        }
    });
    // Process disturbed blocks:
    // For each disturbed block, determine which active interval it belongs to (using its start time)
    // and then add the block’s full duration to that interval’s active minutes.
    // Also, measure the overlap with the minute-by-minute assignment for that interval.
    const activeMinutesMap = activeOnCallIntervals.reduce((acc, curr) => {
        acc[curr.id] = 0;
        return acc;
    }, {}); // total active minutes credited per interval
    // were originally assigned to that interval
    for (const block of disturbedBlocks) {
        let assignedIntervalId = null;
        // Look for an active interval (only) that applies to the block's start time.
        for (const interval of activeOnCallIntervals) {
            if ((0, common_1.isMinuteInInterval)(block.startDateTime, interval, holidays)) {
                assignedIntervalId = interval.id;
                break;
            }
        }
        if (assignedIntervalId) {
            // Credit the whole block to that active interval.
            activeMinutesMap[assignedIntervalId] += block.length;
            // Any minute covered by the disturbed block we wipe from being considered inactive.
            const blockStartIndex = Math.max(0, block.startDateTime.diff(start, 'minute'));
            const blockEndIndex = Math.min(totalMinutes, block.startDateTime.add(block.length, 'minute').diff(start, 'minute'));
            for (let i = blockStartIndex; i < blockEndIndex; i++) {
                inactiveMinutes[i] = ''; // wipe by setting to empty string
            }
        }
    }
    const allIntervals = [
        ...inactiveOnCallIntervals.map((interval) => {
            const minutes = inactiveMinutes.filter((id) => id === interval.id).length;
            const { compensationAmount, pay } = (0, utils_1.calculateCompensation)({
                basePay,
                minutes,
                interval,
            });
            return {
                id: interval.id,
                title: interval.title,
                activity: interval.activity,
                articleId: interval.articleId ?? null,
                isAdditive: interval.isAdditive,
                minutes,
                pay,
                compensationAmount,
            };
        }),
        ...activeOnCallIntervals.map((interval) => {
            const minutes = activeMinutesMap[interval.id] || 0;
            const { compensationAmount, pay } = (0, utils_1.calculateCompensation)({
                basePay,
                minutes,
                interval,
            });
            return {
                id: interval.id,
                title: interval.title,
                activity: interval.activity,
                articleId: interval.articleId ?? null,
                isAdditive: interval.isAdditive,
                minutes,
                pay,
                compensationAmount,
            };
        }),
    ];
    const intervalResults = allIntervals.filter((interval) => interval.minutes);
    const nonAdditiveIntervals = intervalResults.filter((i) => !i.isAdditive);
    const standardMinutes = Math.max(totalMinutes - (0, lodash_1.sumBy)(nonAdditiveIntervals, 'minutes'), 0);
    const standardCompensationAmount = (0, utils_1.calculateCompensation)({
        basePay,
        minutes: standardMinutes,
        interval: null,
    }).compensationAmount;
    const totalCompensationAmount = (0, lodash_1.sumBy)(allIntervals, 'compensationAmount') + standardCompensationAmount;
    return {
        date: startDateTime,
        totalMinutes,
        totalCompensationAmount,
        standardMinutes,
        standardCompensationAmount,
        intervalResults,
    };
};
exports.calculateOnCallCompensation = calculateOnCallCompensation;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const WeekTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(elements_1.Text, { color: constants_1.Color.DARK_GREY, size: props.small ? 6 : 5, py: props.small ? 0 : 2, bold: true, children: [t.ui.week(), " ", props.week] }));
};
exports.WeekTitle = WeekTitle;

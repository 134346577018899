"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDayBeforeHoliday = exports.isHoliday = void 0;
const get_holidays_1 = require("./get-holidays");
const isHoliday = (country, date) => {
    const holidays = (0, get_holidays_1.getHolidays)(country, date, date);
    return holidays[0] || null;
};
exports.isHoliday = isHoliday;
const isDayBeforeHoliday = (country, date) => {
    const holidays = (0, get_holidays_1.getHolidays)(country, date.add(1, 'day'), date.add(1, 'day'));
    return holidays[0] || null;
};
exports.isDayBeforeHoliday = isDayBeforeHoliday;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicShiftStatus = void 0;
var DynamicShiftStatus;
(function (DynamicShiftStatus) {
    DynamicShiftStatus["APPLICATION_NOT_CHOSEN"] = "APPLICATION_NOT_CHOSEN";
    DynamicShiftStatus["APPLIED"] = "APPLIED";
    DynamicShiftStatus["AVAILABLE"] = "AVAILABLE";
    DynamicShiftStatus["BOOKED"] = "BOOKED";
    DynamicShiftStatus["CANCELED"] = "CANCELED";
    DynamicShiftStatus["OCCUPIED"] = "OCCUPIED";
    DynamicShiftStatus["REJECTED"] = "REJECTED";
    DynamicShiftStatus["WITHDRAWN"] = "WITHDRAWN";
    DynamicShiftStatus["SUGGESTED"] = "SUGGESTED";
    DynamicShiftStatus["ACCEPTED"] = "ACCEPTED";
})(DynamicShiftStatus || (exports.DynamicShiftStatus = DynamicShiftStatus = {}));

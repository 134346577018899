"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useZod = void 0;
const zod_1 = require("zod");
const translate_1 = require("../translate");
const useZod = () => {
    (0, translate_1.useLocale)();
    return zod_1.z;
};
exports.useZod = useZod;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateOrdinaryCompensation = void 0;
const lodash_1 = require("lodash");
const enums_1 = require("@bemlo/enums");
const common_1 = require("./common");
const utils_1 = require("./utils");
/**
 * Main function ported to dayjs.
 * It iterates over each minute in the shift and assigns the first matching interval.
 */
const calculateOrdinaryCompensation = ({ basePay, startDateTime, endDateTime, breakStartDateTime, breakDuration, intervals, holidays, }) => {
    const start = startDateTime;
    let end = endDateTime;
    // If the shift spans midnight, adjust the end time.
    if (end.isBefore(start)) {
        end = end.add(1, 'day');
    }
    const maxMinutes = end.diff(start, 'minute');
    const totalMinutes = maxMinutes - breakDuration;
    const minuteAssignments = new Array(maxMinutes).fill('');
    // For each minute of the shift, try to assign an interval.
    for (let minute = 0; minute < maxMinutes; minute++) {
        const currentTime = start.add(minute, 'minute');
        for (const interval of intervals) {
            if (interval.activity !== enums_1.CompensationIntervalActivity.ORDINARY)
                continue;
            if ((0, common_1.isMinuteInInterval)(currentTime, interval, holidays)) {
                minuteAssignments[minute] = interval.id;
                break; // assign only the first matching interval
            }
        }
    }
    // Mark break minutes.
    const breakStartIndex = breakStartDateTime.diff(start, 'minute');
    for (let i = breakStartIndex; i < breakStartIndex + breakDuration; i++) {
        if (i >= 0 && i < minuteAssignments.length) {
            minuteAssignments[i] = 'break';
        }
    }
    // Aggregate results per interval.
    const intervalResults = intervals
        .filter((interval) => minuteAssignments.includes(interval.id))
        .map((interval) => {
        const minutes = minuteAssignments.filter((id) => id === interval.id).length;
        const { compensationAmount, pay } = (0, utils_1.calculateCompensation)({
            basePay,
            minutes,
            interval,
        });
        return {
            id: interval.id,
            title: interval.title,
            activity: interval.activity,
            articleId: interval.articleId ?? null,
            isAdditive: interval.isAdditive,
            minutes,
            pay,
            compensationAmount,
        };
    });
    const nonAdditiveIntervals = intervalResults.filter((i) => !i.isAdditive);
    const standardMinutes = totalMinutes - (0, lodash_1.sumBy)(nonAdditiveIntervals, 'minutes');
    const standardCompensationAmount = (0, utils_1.calculateCompensation)({
        basePay,
        minutes: standardMinutes,
        interval: null,
    }).compensationAmount;
    const totalCompensationAmount = (0, lodash_1.sumBy)(intervalResults, 'compensationAmount') + standardCompensationAmount;
    return {
        date: startDateTime,
        totalMinutes,
        totalCompensationAmount,
        standardMinutes,
        standardCompensationAmount,
        intervalResults,
    };
};
exports.calculateOrdinaryCompensation = calculateOrdinaryCompensation;

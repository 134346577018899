import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { TableBody } from './Body'
import { TableCell } from './Cell'
import { TableGroupHeader } from './GroupHeader'
import { TableHead } from './Head'
import { TableHeadCell } from './HeadCell'
import { TableRow } from './Row'
import { TablePropsContext } from './useTableProps'
import { Color, theme, mediaBreakpointGte } from '../imports'

const TablePropsContextProvider = (props: TableProps) => {
  return (
    <TablePropsContext.Provider value={props}>
      {props.children}
    </TablePropsContext.Provider>
  )
}

const TableElement = styled.table<TableProps>((props) => ({
  borderCollapse: 'collapse',
  lineHeight: 'inherit',
  tableLayout: props.fixed ? 'fixed' : undefined,
  width: '100%',
  'td, th': {
    borderBottom: 'none',
  },
  [mediaBreakpointGte('md')]: {
    'td, th': {
      borderBottom: props.bordered
        ? `1px solid ${Color.MEDIUM_GREY}`
        : undefined,
    },
  },
}))

const TableRoot = styled.div<TableProps>((props) => ({
  backgroundColor: Color.WHITE,
  border: props.borderless ? undefined : theme.border.style.thin,
  borderRadius: props.borderless ? undefined : theme.border.radius.normal,
  boxShadow:
    props.borderless || props.noBoxShadow ? 'none' : theme.boxShadow.normal,
  flexGrow: 1,
  overflowX: props.overflowable || props.responsive ? undefined : 'auto',
  ...(props.overflowable && {
    overflowY: 'auto',
    height: 0,
  }),
}))

type TableProps = {
  bordered?: boolean
  children?: ReactNode
  borderless?: boolean
  fixed?: boolean
  hoverable?: boolean
  noBoxShadow?: boolean
  overflowable?: boolean
  responsive?: boolean
  striped?: boolean
}

const Table = (props: TableProps) => {
  return (
    <TablePropsContextProvider {...props}>
      <TableRoot
        overflowable={props.overflowable}
        responsive={props.responsive}
        borderless={props.borderless}
      >
        <TableElement bordered={props.bordered} fixed={props.fixed}>
          {props.children}
        </TableElement>
      </TableRoot>
    </TablePropsContextProvider>
  )
}

Table.Body = TableBody
Table.Cell = TableCell
Table.Head = TableHead
Table.HeadCell = TableHeadCell
Table.Row = TableRow
Table.GroupHeader = TableGroupHeader

export type { TableProps }
export { Table }

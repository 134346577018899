"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComparatorOperations = void 0;
const react_1 = require("react");
const translate_1 = require("../../../translate");
const filterOperations = (operations, numberOfSelectedItems, noNegations, showPartOf) => {
    const showMulti = numberOfSelectedItems > 1;
    return operations
        .filter(({ isMulti }) => !!isMulti === showMulti)
        .filter(({ isNegation }) => !isNegation || !noNegations)
        .filter(({ isPartOf }) => !isPartOf || showPartOf);
};
const useComparatorOperations = (type, numberOfSelectedItems, noNegations, showPartOf) => {
    const t = (0, translate_1.useTranslations)();
    return (0, react_1.useMemo)(() => {
        if (type === 'value') {
            return filterOperations([
                { value: 'is', label: t.ui.is() },
                { value: 'is_not', label: t.ui.is_not(), isNegation: true },
                {
                    value: 'is_any_of',
                    label: t.ui.is_any_of(),
                    isMulti: true,
                },
                {
                    value: 'is_part_of',
                    label: t.ui.is_part_of(),
                    isPartOf: true,
                },
                {
                    value: 'is_not_part_of',
                    label: t.ui.is_not_part_of(),
                    isPartOf: true,
                    isNegation: true,
                },
            ], numberOfSelectedItems, noNegations || false, showPartOf || false);
        }
        if (type === 'date') {
            return [
                {
                    value: 'is_after',
                    label: t.ui.is_after(),
                },
                {
                    value: 'is_before',
                    label: t.ui.is_before(),
                },
            ];
        }
        return filterOperations([
            {
                value: 'includes',
                label: t.ui.includes(),
            },
            {
                value: 'not_includes',
                label: t.ui.not_includes(),
                isNegation: true,
            },
            {
                value: 'includes_any_of',
                label: t.ui.includes_any_of(),
                isMulti: true,
            },
            {
                value: 'includes_all_of',
                label: t.ui.includes_all_of(),
                isMulti: true,
            },
            {
                value: 'includes_none_of',
                label: t.ui.includes_none_of(),
                isMulti: true,
                isNegation: true,
            },
        ], numberOfSelectedItems, noNegations || false, showPartOf || false);
    }, [type, numberOfSelectedItems]);
};
exports.useComparatorOperations = useComparatorOperations;

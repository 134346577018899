import styled from '@emotion/styled'

import { mediaBreakpointGte } from '../imports'

export type TableCellProps = {
  align?: 'left' | 'center' | 'right'
  nowrap?: boolean
}

export const TableCell = styled.td<TableCellProps>((props) => ({
  paddingBottom: 4,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 4,
  textAlign: props.align,
  whiteSpace: props.nowrap ? 'nowrap' : 'normal',
  '> *': {
    verticalAlign: 'middle',
  },
  [mediaBreakpointGte('md')]: {
    paddingBottom: 10,
    paddingTop: 10,
  },
}))

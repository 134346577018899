import { Market } from '@bemlo/enums'
import { MenuItemArea } from '../config/enums'
import { currentMarket } from '../config/market-configs'
import { useTranslation } from '../translations'
import { useMenuItems } from './hooks/useMenuItems'
import { useSiteMetadata } from './hooks/useSiteMetadata'

import styled from '@emotion/styled'
import {
  BemloLogo,
  Box,
  Color,
  Column,
  Container,
  HorizontalRule,
  Row,
  Section,
  Text,
} from '../ui'
import { Link } from './WrappedLink'

const StyledBemloLogo = styled(BemloLogo)`
  margin-right: 32px;
  width: 96px;
`

export type FooterLink = {
  label: string
  url: string
  bold?: boolean
  openNewTab?: boolean
}

export type FooterProps = {
  companyName: string
  links: FooterLink[]
}

const FooterUI = (props: FooterProps) => {
  return (
    <Section
      background={Color.BLUE}
      color={Color.WHITE}
      noSelect
      spacing="compact"
    >
      <Container>
        <Column alignItems="flex-start">
          {props.links.map(({ url, label, bold, openNewTab }) => (
            <Box key={url} py={2}>
              <Link to={url} openNewTab={openNewTab}>
                <Text bold={bold}>{label}</Text>
              </Link>
            </Box>
          ))}
        </Column>
        <HorizontalRule mt={4} opacity={0.3} />
        <Row alignItems="center" height={64}>
          <StyledBemloLogo color={Color.WHITE} />
          <Text color={Color.LIGHTER_BLUE}>
            &copy; {new Date().getFullYear()} {props.companyName}
          </Text>
        </Row>
      </Container>
    </Section>
  )
}

export const Footer = () => {
  const t = useTranslation('footer')
  const meta = useSiteMetadata()
  const menuItems = useMenuItems({ menuItemArea: MenuItemArea.FOOTER })

  const links: FooterLink[] = menuItems.slice()

  const market = currentMarket

  // Cookie policy

  if (market === Market.SE) {
    links.push({
      url: '/cookies',
      label: t.cookie_policy,
    })
  }

  // Career page

  links.push({
    url: meta.siteCareerUrl,
    label: t.career_cta,
    openNewTab: true,
    bold: true,
  })

  // Backlinks

  if (market !== Market.SE) {
    links.push({
      url: 'https://www.bemlo.se',
      label: 'Bemlo Sverige',
      openNewTab: true,
      bold: true,
    })
  }

  if (market !== Market.NO) {
    links.push({
      url: 'https://www.bemlo.no',
      label: 'Bemlo Norge',
      openNewTab: true,
      bold: true,
    })
  }

  if (market !== Market.DK) {
    links.push({
      url: 'https://www.bemlo.dk',
      label: 'Bemlo Danmark',
      openNewTab: true,
      bold: true,
    })
  }

  if (market !== Market.FI) {
    links.push({
      url: 'https://www.bemlo.fi',
      label: 'Bemlo Suomi',
      openNewTab: true,
      bold: true,
    })
  }

  return <FooterUI companyName={meta.companyName} links={links} />
}
